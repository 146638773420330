import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import { loadFonts } from './plugins/webfontloader'
import { useVuelidate } from '@vuelidate/core'

//import { firebase } from 'firebase/app';
//import 'firebase/auth'
import { initializeApp} from 'firebase/app'

const firebaseConfig = {
  apiKey: "AIzaSyAfY4lEbWox5bS9G3tsfE7F1AQ56T8aXUo",
  authDomain: "aston-dive.firebaseapp.com",
  databaseURL: "https://aston-dive.firebaseio.com",
  projectId: "aston-dive",
  storageBucket: "aston-dive.appspot.com",
  messagingSenderId: "166707664054",
  appId: "1:166707664054:web:411f80bc81328622ef4217",
  measurementId: "G-Z8MWPN3NE1"
};
// Initialize Firebase
//firebase.

export const app = initializeApp(firebaseConfig);
loadFonts()

createApp(App)
  .use(router)
  .use(store)
  .use(vuetify)
  .use(useVuelidate)
  .mount('#app')
