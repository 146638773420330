<template>
  <v-container v-for="page in pages" :key="page.section_order"  fluid class="px-0 py-0" >
    <v-row no-gutters>
      <v-col v-for="product in page.products" :key="product" :cols=page.layout.cols 
                                              :xl=page.layout.xl :lg=page.layout.lg 
                                              :md=page.layout.md :sm=page.layout.sm 
                                              :sx=page.layout.sx>
        <v-card  height="100%" class="pa-3" flat tile>
          <v-parallax v-if="size(product.image) > 0" height="300" max-height="50%"  
                                              :src="product.image" ></v-parallax>
          <v-card-text class="text--primary" >
            <div><h1> {{product.title}}</h1></div>
            <br/>
            <div>
              <p style="text-align: justify" > 
              <span v-html="product.description"></span>
              </p>
            </div>
            <div>
              <v-row no-gutters class="ma-0 py-0 " >
                <v-col v-for="action in product.actions"  :key="action" :cols=page.layout.cols 
                                                          :xl=page.layout.xl :lg=page.layout.lg 
                                                          :md=page.layout.md :sm=page.layout.sm 
                                                          :sx=page.layout.sx>
                  <v-card class="py-0 px-0 ma-0 pa-0" flat tile > 
                    <v-card-actions>
                      <v-row no-gutters class="px-0 ma-0 pt-0 pb-2"  >
                        <v-btn text class="pa-n5 ma-n0" 
                                    color="deep-purple accent-4" 
                                    style="background-color: silver;" 
                                    outlined 
                                    :to=action.route>
                          {{ action.title }}
                        </v-btn>
                      </v-row>
                    </v-card-actions>
                  </v-card>
                </v-col>
              </v-row>
            </div>
          </v-card-text>
          <v-card-actions bottom  class="ma-0 py-0 " style="position: absolute; bottom: -10px;" >
            <div v-for="button in product.buttons" :key="button">
              <v-chip class="ma-2" 
                  :text-color=getTextColour(button) 
                  :color= getBackgroundColour(button)
                  :href= getLink(button)
                  variant="flat"
                  :prepend-icon=getIcon(button)> 
                  {{getText(button)}}
              </v-chip>
            </div> 
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col class="ma-0 py-0 cardish" v-for="card in page.cards"  :key="card" :cols=page.layout.cols :xl=page.layout.xl
                                          :lg=page.layout.lg :md=page.layout.md :sm=page.layout.sm :sx=page.layout.sx>
      <v-card>
        <v-card-title v-if="size(card.title)" class="text-center ma-0">
          {{ card.title }}
        </v-card-title>      
        <v-card-subtitle v-if="size(card.subtitle)" class="text-center ma-0 text-pre-wrap">
          {{ card.subtitle }}
        </v-card-subtitle>
          <v-row>
            <v-col  cols="12" lg="2" md="3" sm="4" sx="5" >
              <v-parallax v-if="size(card.image) > 0" style="border-width 10px height 400px;" :src=card.image></v-parallax>
            </v-col>
            <v-col  cols="12" lg="10" md="9" sm="8" sx="7" >
              <v-card-text class="text-left ma-3">
                <span v-html="card.description"></span></v-card-text>
            </v-col>
          </v-row>
        </v-card>
      </v-col>

      <v-col  v-for="carousel in page.carousels"  class="ma-0 py-0 "
            :key="carousel" :cols=page.layout.cols :xl=page.layout.xl
            :lg=page.layout.lg :md=page.layout.md :sm=page.layout.sm :sx=page.layout.sx>
        <v-row class="text-left ma-1">
          <v-carousel  cycle height = "300" hide-delimiters show-arrows="hover">
            <v-carousel-item v-for="(slide, i) in carousel.slides" :key="i" reverse-transition="fade-transition" transition="none">
              <v-table> 
                
                    <tbody>
                    <tr class="text-left"> 
                      <td>&nbsp;&nbsp;&nbsp;</td>
                      <td>{{index(carousel.slides,i,-2)}} </td>
                      <td class="font-weight-light">{{first(carousel.slides,i)}} </td>
                      <td>&nbsp;&nbsp;&nbsp;</td>
                    </tr>
                    <tr class="text-left"> 
                      <td>&nbsp;&nbsp;&nbsp;</td>
                      <td>{{index(carousel.slides,i,-1)}} </td>
                      <td class="font-weight-regular">{{before(carousel.slides,i)}}</td>
                      <td>&nbsp;&nbsp;&nbsp;</td>
                    </tr>
                    <tr class="text-left"> 
                      <td>&nbsp;&nbsp;&nbsp;</td>
                      <td>{{index(carousel.slides,i,0)}}  </td>
                      <td class="font-weight-bold">{{carousel.slides[i]}}</td>
                      <td>&nbsp;&nbsp;&nbsp;</td>
                    </tr>
                    <tr class="text-left"> 
                      <td>&nbsp;&nbsp;&nbsp;</td>
                      <td>{{index(carousel.slides,i,+1)}} </td>
                      <td class="font-weight-regular">{{after(carousel.slides,i)}} </td>
                      <td>&nbsp;&nbsp;&nbsp;</td>
                    </tr>
                    <tr class="text-left"> 
                      <td>&nbsp;&nbsp;&nbsp;</td>
                      <td>{{index(carousel.slides,i,+2)}}  </td>
                      <td class="font-weight-light">{{last(carousel.slides,i)}}  </td>
                    </tr>
                  </tbody>
              </v-table>
            </v-carousel-item>
          </v-carousel>
        </v-row>
      </v-col>
      <v-col class="py-0 px-2 ma-0 pa-0 " v-for="(field, index) in page.fields" :key="index" :cols=page.layout.cols :xl=page.layout.xl :lg=page.layout.lg 
                                                              :md=page.layout.md :sm=page.layout.sm :sx=page.layout.sx>
        <v-text-field v-if="field.type==='TextField'"
            v-model=page.fields[index].value 
            :label=field.label
            :error-messages="field.errorDisplayed" 
            :error="field.errorVisible"
            @input="check(field.name)"
          ></v-text-field>
          <v-textarea  v-if="field.type=='TextArea'"  
            v-model=page.fields[index].value 
            :label=field.label 
            :error-messages="field.errorDisplayed" 
            :error="field.errorVisible" 
            @input="check(field.name)"
          ></v-textarea> 
          <v-select v-if="field.type=='Select'" 
              v-model=page.fields[index].value
              :items=field.items 
              :label=field.label
              @click="check(field.name)"
          ></v-select>
      </v-col>
      <v-col class="ma-0 py-0 " v-if="hasProperty(page,'submissions')" cols="12" xl="12" lg="12" md="12" sm="12" sx="12">
        <div id="block_container" v-for="(submission,index) in page.submissions" :key="index" >
          <v-btn class="mr-1" @click="submit(submission.name)"> {{ submission.name }} </v-btn>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import {toRef   } from 'vue'
  import axios from 'axios'
  var api 
  var isFormCorrect = true
  const errors = new Array()

  export default {
    data(){
      return {
        pages : this.getContent()
      }
    },
    methods: {
      size (obj) {
        if (obj === null){
          return 0
        }
        return obj.length 
      },
      hasProperty(obj,property){
        try {
          if ((obj === null)||(property === null)||(this.size(obj) === 0)){
            return false
          }
          //console.log(property, JSON.stringify(obj) ,this.size(obj))
          return property in obj
        } catch(e){
          return false;
        } finally {
          //console.log ("exception")
        }

      },
      getTextColour(button){
        return button.color
      },
      getBackgroundColour(button){
        return button.background       
      },
      getIcon(button){
        console.log(JSON.stringify(button))
        if('avatar' in button && button.avatar != null){
          return button.avatar.icon
        } 
        return null
      },
      getText(button){
        if('avatar' in button && button.avatar != null){
          return button.avatar.text + " " + button.avatar.caption
        } else {
          return button.text
        }
      },
      getLink(button){
        if (('href' in button) && (button.href != null) ){
          console.log("ref:"+JSON.stringify(button))
          return button.href
        } else {
          console.log("to:"+JSON.stringify(button))
          return button.to
        }
      },
      index(slides,index,offset){
        var no = index + offset;
        if (no < 0) {
          return " "
        }
        if (no >= slides.length){
          return " "
        }
        return `(${no+1})`
      },
      first(slides, index){
        if (index < 2){
          return " "
        } else {
          return slides[index-1]
        }
      },
      before(slides, index){
        if (index === 0){
          return " "
        } else {
          return slides[index-1]
        }
      },
      after(slides, index){
        if (index===slides.length){
          return " "
        } else {
          return slides[index+1]
        }
      },
      last(slides, index){
        if (index>slides.length-2){
          return " "
        } else {
          return slides[index+2]
        }
      },
      submit (button) {
        console.log ("Submit"+JSON.stringify(button))
        for (let page of this.pages){
          console.log ("Page"+JSON.stringify(page))
          if (this.hasProperty(page,'submissions')){
            for (let submission of page.submissions){
              console.log(submission)
              if (button === submission.name) {
                if (submission.isCancel) {
                  this.$router.go(-1);
                } else {
                  isFormCorrect = this.check("ALL")
                  if (!isFormCorrect) {
                    console.log("error in form")
                  } else {
                    axios .post(api+submission.api,{"subject":submission.subject,"message":this.createRequest(page,submission)})
                          .then(function (response){
                            console.log(response);
                          }.bind(this));
                    this.$router.push('/');
                  }
                }
              }
            }
          }
        }
      },
      createRequest(page,submission){
        var request = new Array()
        for (const field of page.fields){
          if (submission.fields.includes(field.name)) {
            const item= { name: field.name, value: field.value}
            request.push(item)
          }
        }  
        console.log(JSON.stringify(request))
        return request;
      },
      check(param){
        let correct  = true  
        while (errors.length > 0) {
          errors.pop();
        }
        for(const page of this.pages) {
          if (this.hasProperty(page,'fields')){
            for (const field of page.fields){
            console.log ("Page:"+page.name+" Param: "+param+ " field:"+field)
              if (param === field.name || param === "ALL" ){
                if (field.type === 'TextField' || field.type == 'TextArea' ){
                  if (field.regex != null && field.regex.length > 0){
                    let text = field.value
                    const pattern = RegExp(field.regex);
                    let found = pattern.test(text)
                    if(!found){
                      field.errorDisplayed = field.error;
                      correct = false;
                      errors.push(field.error)
                    } else {
                      field.errorDisplayed = "";
                    }
                  }
                } 
              }
            }
          } else {
            console.log ("Page:"+page.name+" Param: "+param+ " "+JSON.stringify(page))
          }
        }
        return correct 
      },
      prepareFields(props){
        let i=0;
        for(const page of this.pages) {
          console.log ("Created :: "+page.name)
          if (this.hasProperty(page,'fields')){
            for (const field of page.fields){
              console.log("param ["+i+"] = "+field.name)
              if (field.prop !== null && field.prop.length > 0){
                page.fields[i].value  = toRef(props, field.prop).value
                //page.fields[i].value  = params[field.prop].value
              }
              i++;
            }
          }
        }
      },
      prepareApi(){
        if(window.location.host.toLowerCase().includes("localhost")){
            api = "https://astondive.co.uk/law/staging/"
          } else {
            api = "https://astondive.co.uk/law/v1/"
          }
      },
      getContentByPageName(pageName){  
        console.log("Getting via pageName::"+pageName[0])
        const api = (window.location.host.toLowerCase().includes("localhost"))?
              "https://astondive.co.uk/law/staging/" : "https://astondive.co.uk/law/v1/"
        console.log("API::"+api)
        var url= api+"getContent.php?page="+pageName[0];

        var xhr = new XMLHttpRequest()
        xhr.open('GET',url, false)
        xhr.send(null)
        if (xhr.status === 200) {
          return xhr.response;
        } else {
          throw new Error('Request failed: ' + xhr.statusText);
        }
      },
      getContent(){
          const urlParams = window.location.href.substring(window.location.href.indexOf("#")+10).split("/");
          var content = JSON.parse(this.getContentByPageName(urlParams))
          let i=0;
          let k=0;
          for(const page of content) {
            if (this.hasProperty(page,'fields')){
              for (const field of page.fields){
                if (field.prop !== null && field.prop.length > 0){
                  for (let j=1; j< urlParams.length ;j++){
                    var param = urlParams[j].split("=")
                    if(param[0]===field.prop){
                      content[k].fields[i].value  = decodeURI(param[1])
                    }
                  }
                }
                i++;
              }
            }
            k++;
          }
          return content
        },
    },
  }
</script>


<style scoped>
.cardish {
    background-color: blueviolet;
}
</style>