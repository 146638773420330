import { createRouter, createWebHashHistory } from 'vue-router'

import {getAuth, fetchSignInMethodsForEmail,isSignInWithEmailLink,signInWithEmailLink,EmailAuthProvider } from 'firebase/auth';
import store from '../store'
import Login from '../components/LoginPage'
import Board from '../components/PageBoard'
import Contact from '../components/PageContact'
import PageQuality from '../components/PageQuality'
import Quote from '../components/QuotePage'
import ComplaintsProcedure from '../components/ComplaintsProcedure'
import PrivacyPolicy from '../components/PrivacyPolicy'
import TermsOfUse from '../components/TermsOfUse'
import Content from '../components/PageContent'
import PDF from '../components/PDF'

function dynamicPropsFn (route) {
  console.log('dynamic route')
  console.log(route)
  return {
    reference: route.params.reference
  }
}

const routes = [
  {
    path: '/',
    redirect: '/content/HOME'
  },{
    path: '/loginPage',
    name: 'LoginPage',
    component: Login
  },{
    path: '/board',
    name: 'Board',
    component: Board,
    meta: { requiresLogin: true}
  },{
    path: '/Contact/:reference',
    name: 'Contact',
    props: dynamicPropsFn,
    component: Contact
  },{
    path: '/quality',
    name: 'PageQuality',
    component: PageQuality
  },{
    path: '/privacyPolicy',
    name: 'PrivacyPolicy',
    component: PrivacyPolicy
  },{
    path: '/pdf',
    name: 'PDF',
    component: PDF,
    meta: { requiresLogin: true}
  },{
    path: '/termsOfUse',
    name: 'TermsOfUse',
    component: TermsOfUse
  },{
    path: '/complaintsProcedure',
    name: 'ComplaintsProcedure',
    component: ComplaintsProcedure
  },{
    path: '/login',
    name: 'Login',
    component: Login
  },{
    path: '/quote/:pathMatch(.*)*',
    name: 'Quote',
    component: Quote
  },{
    path: '/content/:pathMatch(.*)*',
    name: 'Content',
    component: Content
  
  }
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes
})


router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresLogin)) {
    
    const user = getAuth().currentUser
    if (user) {
        console.log('User is logged in');
        var name = user.displayName;
        if (name === null){
          name = user.email
        }
        store.commit("setName", name);
        next();
    } else {
        console.log('User is not logged in.');
        store.commit("setName", null);
        next("/Login")
    }
  } else
  if (to.matched.some(record => record.meta.requiresAuth)){
    // Confirm the link is a sign-in with email link.
    var email = window.prompt('Please provide your email for confirmation');

    fetchSignInMethodsForEmail(getAuth(),email)
      .then((signInMethods) => {
      // This returns the same array as fetchProvidersForEmail but for email
      // provider identified by 'password' string, signInMethods would contain 2
      // different strings:
      // 'emailLink' if the user previously signed in with an email/link
      // 'password' if the user has a password.
      // A user could have both.
      if (signInMethods.indexOf(EmailAuthProvider.EMAIL_PASSWORD_SIGN_IN_METHOD) != -1) {
                window.alert(`password`);
        // User can sign in with email/password.
      }
      if (signInMethods.indexOf(EmailAuthProvider.EMAIL_LINK_SIGN_IN_METHOD) != -1) {
                window.alert(`link`);
      }

      window.alert(`other ${signInMethods} | ${window.location.href}`);
    })
    .catch((error) => {
      console.log(error)
      // Some error occurred, you can inspect the code: error.code
    })
    console.log(fetchSignInMethodsForEmail(email));
    if (isSignInWithEmailLink(getAuth(),window.location.href)) {
      // Additional state parameters can also be passed via URL.
      // This can be used to continue the user's intended action before triggering
      // the sign-in operation.
      // Get the email if available. This should be available if the user completes
      // the flow on the same device where they started it.
      console.log(`email ${email}`)
      // The client SDK will parse the code from the link for you.
      signInWithEmailLink(getAuth(),email, window.location.href)
        .then((result) => {
          // Clear email from storage.
          window.alert(`RESULT ${email}`);
          window.localStorage.removeItem('emailForSignIn');
          console.log("Result");
          console.log(result)
          // You can access the new user via result.user
          // Additional user info profile not available via:
          // result.additionalUserInfo.profile == null
          // You can check if the user is new or existing:
          // result.additionalUserInfo.isNewUser
          next();
        })
        .catch((error) => {
          console.log(error)
          // Some error occurred, you can inspect the code: error.code
          // Common errors could be invalid email and invalid or expired OTPs.
          next("/")
        });
    } else {
      if(isSignInWithEmailLink(getAuth(),'https://astondive.page.link/local')){

        window.alert(`alert stupid LOCATION`);
      }
      window.alert(`alert BAD LOCATION`);
      next("/")
    }
  }
  next();
})

export default router
