// import axios from "axios";

export const PropertyModule = {
  state: () => ( {
      buyProperty: true,
      helpToBuy: false,
      beforeJuly: false,
      leasehold: false,
      leaseholdSale: false,
      mortgage: false,
      mortgageSale: false,
      newBuild: false,
      propertyPrice: 0,
      remortgage: false,
      remortgagePrice: 0,
      rightToBuy: false,
      salePrice: 0,
      sellProperty: true,
      stampDuty: "[]",
      purchaseFee: "[]",
      salesFee: "[]",
      remortgageFee: "[]",
      landRegistry: "[]",
      lines:"[]",
      tiers:"[]",
      sharedOwnership: false 
  }),
  getters: {
    getBuy: state => state.buyProperty,
    getHelpToBuy: state => state.helpToBuy,
    getBeforeJuly: state => state.beforeJuly,
    getLeasehold: state => state.leasehold,
    getLeaseholdSale: state => state.leaseholdSale,
    getMortgage: state => state.mortgage,
    getMortgageSale: state => state.mortgageSale,
    getNewBuild: state => state.newBuild,
    getPrice: state => state.propertyPrice,
    getRemortgage: state => state.remortgage,
    getRemortgagePrice: state => state.remortgagePrice,
    getRightToBuy: state => state.rightToBuy,
    getSalePrice: state => state.salePrice,
    getSell: state => state.sellProperty,
    getSharedOwnership: state => state.sharedOwnership,
    getStampDuty: state => state.stampDuty,
    getSalesFee: state => state.salesFee,
    getPurchaseFee: state => state.purchaseFee,
    getRemortgageFee: state => state.remortgageFee,
    getLandRegistry: state => state.landRegistry,
    getLines: state => state.lines,
    getTiers: state => state.tiers,
  },
  mutations: {
    setBuy: (state, value) => {
      if (value) {
        state.remortgage = false;
      }
      state.buyProperty = value;
    },
    setHelpToBuy: (state, value) => {
      state.helpToBuy = value;
    },
    setLeasehold: (state, value) => {
      state.leasehold = value;
    },
    setLeaseholdSale: (state, value) => {
      state.leaseholdSale = value;
    },
    setMortgage: (state, value) => {
      state.mortgage = value;
    },
    setMortgageSale: (state, value) => {
      state.mortgageSale = value;
    },
    setNewBuild: (state, value) => {
      state.newBuild = value;
    },
    setBeforeJuly: (state, value) => {
      state.beforeJuly = value;
    },
    setPrice: (state, value) => {
      if (value < 0) {
        state.propertyPrice = 0;
      } else {
        state.propertyPrice = value;
      }
    },
    setRemortgage: (state, value) => {
      if (value) {
        state.sellProperty = false;
        state.buyProperty = false;
      }
      state.remortgage = value;
    },
    setRemortgagePrice: (state, value) => {
      if (value < 0) {
        state.remortgagePrice = 0;
      } else {
        state.remortgagePrice = value;
      }
    },
    setRightToBuy: (state, value) => {
      state.rightToBuy = value;
    },
    setSalePrice: (state, value) => {
      if (value < 0) {
        state.salePrice = 0;
      } else {
        state.salePrice = value;
      }
    },
    setSell: (state, value) => {
      if (value) {
        state.remortgage = false;
      }
      state.sellProperty = value;
    },
    setSharedOwnership: (state, value) => {
      state.sharedOwnership = value;
    },
    setStampDuty: (state, value) => {
      state.stampDuty = value;
    },
    setSalesFee: (state, value) => {
      state.salesFee = value;
    },
    setPurchaseFee: (state, value) => {
      state.purchaseFee = value;
    },
    setRemortgageFee: (state, value) => {
      state.remortgageFee = value;
    },
    setLandRegistry: (state, value) => {
      state.landRegistry = value;
    },
    setLines: (state, value) => {
      state.lines = value;
    },
    setTiers: (state, value) => {
      state.tiers = value;
    }
  }
}