
<template>
  <v-footer class="pa-0 ma-0 footer-outer" color="#0088ff"> 
    <div class="d-flex w-100 align-center px-5 ma-0 footer-outer">
    <!-- <v-system-bar window color="#0088ff"  class="d-flex w-100 align-center px-5 ma-0 footer-outer" > -->
      <strong class="text-h7 text-md-h6 text-lg-h5 ma-0" >Connect with us on social networks!</strong>
      <v-spacer></v-spacer>
      <v-btn v-for="icon in 3" :key="icon" class="mx-4 my-0" dark icon :color=colour[icon-1] :href= hrefs[icon-1] >
        <v-icon size="16px">{{ icons[icon-1] }}</v-icon>
      </v-btn>
    <!-- </v-system-bar> -->
    </div>
  </v-footer>
</template>



<script>
  export default {
    data: () => ({
      colour:["#25d366","#0088ff","#0088ff"],
      icons: [
        'mdi-whatsapp',
        'mdi-facebook',
        'mdi-linkedin',
      ],
      hrefs: [ 
        'https://api.whatsapp.com/send?phone=447588820657&text=I%20have%20a%20query%20for%20Aston-Dive%20Legal%20Services',
        'https://www.facebook.com/Aston-Dive-Legal-Services-108745474362770/?tn-str=k*F',
        'https://www.linkedin.com/in/alena-aston-dive-559ab439/',
      ],
      
    }),
  }
</script>

<style scoped>
.footer-outer {
    height : 60px; 
    max-height: 60px;
}
</style>