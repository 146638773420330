<template>
    <v-container>
        <v-spacer />
        <v-row no-gutters>
            <v-col  cols="12" xl="12" lg="12" md="12" sm="12" sx="12">
                <v-card>
                    <v-card-title>Privacy Policy</v-card-title>
                    <v-card-text style="text-align: justify">
                        <H2 CLASS="western">
                            <FONT COLOR="#000000" FACE="Times New Roman, serif">
                                <FONT SIZE=2 STYLE="font-size: 11pt">Privacy policy &ndash; Website Users</FONT>
                            </FONT>
                        </H2>
                        <P CLASS="western">
                            <FONT COLOR="#000000" FACE="Times New Roman, serif">
                                <FONT SIZE=2 STYLE="font-size: 11pt">   This policy (together with our <U>Terms of Use</U>) sets out the basis on which we 
                                                                        will process any personal data that you provide to us. Please read it carefully.</FONT>
                            </FONT>
                        </P>
                        <H3 CLASS="western">
                            <FONT COLOR="#000000" FACE="Times New Roman, serif">
                                <FONT SIZE=2 STYLE="font-size: 11pt">Data Controller</FONT>
                            </FONT>
                        </H3>
                        <P CLASS="western">
                            <FONT COLOR="#000000" FACE="Times New Roman, serif">
                                <FONT SIZE=2 STYLE="font-size: 11pt">
                                    For the purposes of the Data Protection Act 1998 (the Act) and the EU General Data Protection Regulation  
                                    (&ldquo;GDPR&rdquo;), the data controller is ASTON-DIVE CONSULTANTS LIMITED of Delta House, 16 Bridge Road, Haywards Heath, West Sussex, RH16 1UA, United Kingdom also trading under the name of ASTON-DIVE LEGAL SERVICES (&ldquo;the Company&rdquo;).
                                </FONT>
                            </FONT>
                        </P>
                        <P ALIGN=LEFT STYLE="margin-top: 0.42cm; margin-bottom: 0.42cm; line-height: 0.53cm; page-break-after: avoid">
                            <A NAME="a216371"></A>
                            <FONT COLOR="#000000">
                                <FONT FACE="Times New Roman, serif">
                                    <FONT SIZE=2 STYLE="font-size: 11pt">
                                        <B>Information
                                    we collect from you</B>
                                    </FONT>
                                </FONT>
                            </FONT>
                        </P>
                        <P ALIGN=LEFT STYLE="margin-top: 0.21cm; line-height: 0.53cm">
                            <FONT COLOR="#000000">
                                <FONT FACE="Times New Roman, serif">
                                    <FONT SIZE=2 STYLE="font-size: 11pt">We will collect and process the following data about you:</FONT>
                                </FONT>
                            </FONT>
                        </P>
                        <UL>
                            <LI>
                                <P ALIGN=LEFT STYLE="margin-top: 0.21cm; line-height: 0.53cm">
                                    <FONT COLOR="#000000">
                                        <FONT FACE="Arial, sans-serif">
                                            <FONT FACE="Times New Roman, serif">
                                                <FONT SIZE=2 STYLE="font-size: 11pt">
                                                    <B>Information you give us.</B>
                                                </FONT>
                                            </FONT>
                                            <FONT FACE="Times New Roman, serif">
                                                <FONT SIZE=2 STYLE="font-size: 11pt">
                                                    This is information about you that you give us by filling in forms on our site 
                                                    https://www.astondive.co.uk/ (
                                                </FONT>
                                            </FONT>
                                            <FONT COLOR="#000000">
                                                <FONT FACE="Arial, sans-serif">
                                                    <B><FONT FACE="Times New Roman, serif"><FONT SIZE=2 STYLE="font-size: 11pt">our site</FONT></FONT></B>
                                                </FONT>
                                            </FONT>
                                            <FONT FACE="Times New Roman, serif">
                                                <FONT SIZE=2 STYLE="font-size: 11pt">
                                                    ) or by corresponding with us by phone, e-mail or otherwise. It includes information you provide 
                                                    when you use our site. The information you give us may include your name, address, e-mail address 
                                                    and phone number, further details about legal services you require, purchase/sale price of the 
                                                    property you are seeking a quotation for, and any other information you provide, including copies 
                                                    of documents that you send to us by email.
                                                </FONT>
                                            </FONT>
                                        </FONT>
                                    </FONT>
                                </P>
                            </li>
                        </UL>
                        <H3 CLASS="western" ALIGN=LEFT>
                            <FONT COLOR="#000000" FACE="Times New Roman, serif">
                                <FONT SIZE=2 STYLE="font-size: 11pt">Processing your data</FONT>
                            </FONT>
                        </H3>
                        <P CLASS="western">
                            <FONT COLOR="#000000" FACE="Times New Roman, serif">
                                <FONT SIZE=2 STYLE="font-size: 11pt">Your personal data is processed in the following ways: </FONT>
                            </FONT>
                        </P>
                        <H4 CLASS="western">
                            <FONT COLOR="#000000" FACE="Times New Roman, serif">
                                <FONT SIZE=2 STYLE="font-size: 11pt">Quotes for property services</FONT>
                            </FONT>
                        </H4>
                        <P CLASS="western">
                            <FONT COLOR="#000000" FACE="Times New Roman, serif">
                                <FONT SIZE=2 STYLE="font-size: 11pt">Your personal data is collected by the Company and passed onto Alena Aston-Dive 
                                    Consultant Solicitor who partners with other regulated law firms to achieve the best outcomes for your transaction 
                                    (&ldquo;the Consultant&rdquo;). If you chose to use the services of the Consultant, you will be instructing 
                                    another regulated law firm and your personal data will be processed in accordance with their policies, which you 
                                    will be made aware of in their client care letter, emails, website links, and terms of business. <br/><br/>
                                    <B>Other legal services</B>
                                </FONT>
                            </FONT>
                        </P>
                        <P CLASS="western">
                            <FONT COLOR="#000000" FACE="Times New Roman, serif">
                                <FONT SIZE=2 STYLE="font-size: 11pt">Your personal data is collected by the Company and passed onto Alena Aston-Dive 
                                    Consultant Solicitor of other regulated law firms (&ldquo;the Consultant&rdquo;) who will then pass your details 
                                    to another Consultant Solicitor of another regulated law firm (&ldquo;the other Consultant Solicitor&rdquo;) or 
                                    to another regulated law firm to find a suitable contact to advise you on your situation. If you chose to use the 
                                    services of the other Consultant Solicitor, you will be instructing another regulated law firm and your personal 
                                    data will be processed in accordance with their policies, which you will be made aware of in their client care 
                                    letter, emails, website links, and terms of business. </FONT>
                            </FONT>
                        </P>
                        <P CLASS="western">
                            <FONT COLOR="#000000" FACE="Times New Roman, serif">
                                <FONT SIZE=2 STYLE="font-size: 11pt">
                                    <B>Notarial services</B>
                                </FONT>
                            </FONT>
                        </P>
                        <P CLASS="western">
                            <FONT COLOR="#000000" FACE="Times New Roman, serif">
                                <FONT SIZE=2 STYLE="font-size: 11pt">Your personal data is collected by the Company and passed onto Alena Aston-Dive 
                                    Notary Public of Aston-Dive Notary &amp; Co (&ldquo;the Notary&rdquo;). If you chose to use the services of the 
                                    Notary, you will be instructing Aston-Dive Notary &amp; Co and your personal data will be processed in accordance 
                                    with their policies, which are available on their website </FONT>
                            </FONT>
                            <A HREF="https://www.aston-dive.co.uk/">
                                <FONT FACE="Times New Roman, serif">
                                    <FONT SIZE=2 STYLE="font-size: 11pt">https://www.aston-dive.co.uk/</FONT>
                                </FONT>
                            </A>
                            <FONT FACE="Times New Roman, serif">
                                <FONT SIZE=2 STYLE="font-size: 11pt">.</FONT>
                            </FONT>
                        </P>
                        <H2 CLASS="western">
                            <FONT COLOR="#000000" FACE="Times New Roman, serif">
                                <FONT SIZE=2 STYLE="font-size: 11pt">Where we store your personal data</FONT>
                            </FONT>
                        </H2>
                        <P CLASS="western">
                            <FONT COLOR="#000000" FACE="Times New Roman, serif">
                                <FONT SIZE=2 STYLE="font-size: 11pt">Your data will usually be held in a destination inside the European Economic
                                    Area (&quot;EEA&quot;). The EEA consists of the 28 EU Member States plus Norway, Liechtenstein and Iceland. 
                                    However, there may be times when the data we collect from you may be transferred to, and stored at, a destination 
                                    outside the European Economic Area (&quot;EEA&quot;).
                                    <br/>By submitting your personal data, you agree to this transfer, storing or processing. We will take all steps 
                                    reasonably necessary to ensure that your data are maintained securely and in accordance with this privacy policy 
                                    and applicable data protection law.<br/></FONT>
                            </FONT><br/><br/>
                        </P>
                        <P CLASS="western">
                            <FONT COLOR="#000000" FACE="Times New Roman, serif">
                                <FONT COLOR="#000000" SIZE=2 STYLE="font-size: 11pt">
                                    <B>Disclosure of your information</B>
                                </FONT>
                            </FONT>
                        </P>
                        <P CLASS="western">
                            <FONT COLOR="#000000" FACE="Times New Roman, serif">
                                <FONT COLOR="#000000" SIZE=2 STYLE="font-size: 11pt">We may disclose your personal information to third parties:</FONT>
                            </FONT>
                        </P>
                        <UL>
                            <LI>
                                <P ALIGN=LEFT STYLE="margin-bottom: 0cm">
                                    <FONT COLOR="#000000">
                                        <FONT FACE="Times New Roman, serif">
                                            <FONT SIZE=2 STYLE="font-size: 11pt">In the event that we sell or buy any business or assets, in which case 
                                                we will disclose your personal data to the prospective seller or buyer of such business or assets.</FONT>
                                        </FONT>
                                    </FONT>
                                </P>
                            </li>
                            <LI>
                                <P ALIGN=JUSTIFY STYLE="margin-bottom: 0cm">
                                    <FONT COLOR="#000000">
                                        <FONT FACE="Times New Roman, serif">
                                            <FONT SIZE=2 STYLE="font-size: 11pt">If the Company or substantially all of its assets are acquired by a third
                                                party, in which case personal data held by it about its customers will be one of the transferred assets.</FONT>
                                        </FONT>
                                    </FONT>
                                </P>
                            </li>
                            <LI>
                                <P ALIGN=JUSTIFY STYLE="margin-bottom: 0cm">
                                    <FONT COLOR="#000000">
                                        <FONT FACE="Arial, sans-serif">
                                            <FONT FACE="Times New Roman, serif">
                                                <FONT SIZE=2 STYLE="font-size: 11pt">If we are under a duty to disclose or share your personal data, for
                                                    example to a Government agency, in order to comply with any legal obligation, or in order to enforce 
                                                    or apply our terms of use </FONT>
                                            </FONT>
                                            <FONT FACE="Times New Roman, serif">
                                                <FONT SIZE=2 STYLE="font-size: 11pt">
                                                    <a href="/#/termsOfUse">Terms of Use</a> and other agreements; or to protect the rights, property, or
                                                    safety of the Company, our customers, or others. This includes exchanging information with other 
                                                    companies and organisations for the purposes of fraud protection and credit risk reduction.</FONT>
                                            </FONT>
                                        </FONT>
                                    </FONT>
                                </P>
                            </li>
                        </UL>
                        <UL>
                            <P CLASS="western" STYLE="margin-bottom: 0cm"></P>
                        </UL>
                        <P CLASS="western" STYLE="margin-bottom: 0cm">
                            <FONT COLOR="#000000" FACE="Times New Roman, serif">
                                <FONT SIZE=2 STYLE="font-size: 11pt">Our site may contain links to other websites. If you follow a link to any of these 
                                    websites, please note that these websites have their own privacy policies and that we do not accept any responsibility 
                                    or liability for these policies. Please check these policies before you submit any personal data to these websites.</FONT>
                            </FONT>
                        </P>
                        <H2 CLASS="western">
                            <FONT COLOR="#000000" FACE="Times New Roman, serif">
                                <FONT SIZE=2 STYLE="font-size: 11pt">Your rights
                                </FONT>
                            </FONT>
                        </H2>
                        <P CLASS="western">
                            <FONT COLOR="#000000" FACE="Times New Roman, serif">
                                <FONT SIZE=2 STYLE="font-size: 11pt">
                                    You have the following rights in relation to the processing of your data:<br/>&nbsp;
                                </FONT>
                            </FONT>
                        </P>
                        <OL>
                            <LI>
                                <P CLASS="western" STYLE="margin-bottom: 0cm">
                                    <FONT COLOR="#000000" FACE="Times New Roman, serif">
                                        <FONT SIZE=2 STYLE="font-size: 11pt">
                                            The right at any time to withdraw your consent to our use of your data, but if you do so we may no longer be able 
                                            to provide services to you;
                                        </FONT>
                                    </FONT>
                                </P>
                            </li>
                            <LI>
                                <P CLASS="western" STYLE="margin-bottom: 0cm">
                                    <FONT COLOR="#000000" FACE="Times New Roman, serif">
                                        <FONT SIZE=2 STYLE="font-size: 11pt">The right to ask us not to process your personal data for marketing purposes; </FONT>
                                    </FONT>
                                </P>
                            </li>
                            <LI>
                                <P CLASS="western" STYLE="margin-bottom: 0cm">
                                    <FONT COLOR="#000000" FACE="Times New Roman, serif">
                                        <FONT SIZE=2 STYLE="font-size: 11pt">The right to access the information we hold about you and to be provided with a 
                                            copy. If you make more than one such request, we will be entitled to charge a reasonable fee for the second and 
                                            subsequent requests; </FONT>
                                    </FONT>
                                </P>
                            </li>
                            <LI>
                                <P CLASS="western" STYLE="margin-bottom: 0cm">
                                    <FONT COLOR="#000000" FACE="Times New Roman, serif">
                                        <FONT SIZE=2 STYLE="font-size: 11pt">If you consider that any of the information that we hold about you is inaccurate, 
                                            you have the right to ask us to rectify that inaccuracy;
                                        </FONT>
                                    </FONT>
                                </P>
                            </li>
                            <LI>
                                <P CLASS="western" STYLE="margin-bottom: 0cm">
                                    <FONT COLOR="#000000" FACE="Times New Roman, serif">
                                        <FONT SIZE=2 STYLE="font-size: 11pt">If you consider that the information that we hold about you is incomplete, you 
                                            have the right to ask us to complete it; </FONT>
                                    </FONT>
                                </P>
                            </li>
                            <LI>
                                <P CLASS="western" STYLE="margin-bottom: 0cm">
                                    <FONT COLOR="#000000" FACE="Times New Roman, serif">
                                        <FONT SIZE=2 STYLE="font-size: 11pt">The right to ask us to delete the information that we hold about you if, for example, you consider that this information is no longer necessary for the purpose for which we collected it. This is sometimes referred to as &ldquo;the right to be forgotten&rdquo;; </FONT>
                                    </FONT>
                                </P>
                            </li>
                            <LI>
                                <P CLASS="western" STYLE="margin-bottom: 0cm">
                                    <FONT COLOR="#000000" FACE="Times New Roman, serif">
                                        <FONT SIZE=2 STYLE="font-size: 11pt">The right to ask us to suspend processing of your data if: (i) you consider that it is inaccurate; or (ii) its processing is unlawful but you do not wish us to delete it; or (iii) we no longer require the data but you need us to keep it for a legal claim; or (iv) you object to the processing and we are verifying whether we have a legitimate reason to continue processing it; </FONT>
                                    </FONT>
                                </P>
                            </li>
                            <LI>
                                <P CLASS="western">
                                    <FONT COLOR="#000000" FACE="Times New Roman, serif">
                                        <FONT SIZE=2 STYLE="font-size: 11pt">The right to be provided with a copy of the data that we hold about you in a commonly used electronic format. This is sometimes referred to as &ldquo;data portability&rdquo;. </FONT>
                                    </FONT>
                                </P>
                            </li>
                        </OL>
                        <P CLASS="western">
                            <FONT COLOR="#000000" FACE="Times New Roman, serif">
                                <FONT SIZE=2 STYLE="font-size: 11pt">If you wish to use any of these rights you can contact us on us by email at info@aston-dive.com <br/><br/>You also have the right to bring any concerns about our processing of your personal data to the Information Commissioner&rsquo;s Office (&ldquo;ICO&rdquo;). The ICO can be contacted by telephone 0303 123 1113.<br/><br/>
                                    <B>Changes to our
                                    privacy policy</B>
                                </FONT>
                            </FONT>
                        </P>
                        <P CLASS="western">
                            <FONT COLOR="#000000" FACE="Times New Roman, serif">
                                <FONT SIZE=2 STYLE="font-size: 11pt">Any changes we may make to our privacy policy in the future will be posted on this page.</FONT>
                            </FONT><br/><br/><br/><br/>
                        </P>
                        <P CLASS="western" STYLE="margin-bottom: 0cm"><br/>
                        </P>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn text  @click="back" >  Back </v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<style scoped>
a:link {
    text-decoration: none;
}
</style>

<script>
export default {
    methods: {
        back: function () {
            this.$router.go(-1);
        },
    },
}
</script>
