<template>
    <v-container class="grey lighten-5">
        <v-row no-gutters style="flex-wrap: nowrap;">
            
            <v-col cols="4" style="min-width: 100px; max-width: 100%;" class="flex-grow-1 flex-shrink-0"> 
                
                <v-card >
                    <v-card-title>
                        <h1 class="display">Combine two PDF files</h1>
                    </v-card-title>
                    <v-card-text>
                        <v-form>
                            <p>  <input type="file" @change="handleFileUpload" accept=".pdf" name="file1"> </p>
                            <p>  <input type="file" @change="handleFileUpload" accept=".pdf" name="file2"></p>
                        </v-form>
                        <v-card-actions>
                            <v-btn v-on:click="combineFiles" color="success" >Combine Files</v-btn>
                        </v-card-actions>
                    </v-card-text> 
                </v-card>

                <v-card >
                    <v-card-title>
                        <h1 class="display">OCR an Image</h1>
                    </v-card-title>
                    <v-card-text>
                        <v-form>
                            <p>  <input type="file" @change="handleFileUpload" accept=".jpg" name="file1"> </p>
                            {{ ocr }}
                        </v-form>
                        <v-card-actions>
                            <v-btn v-on:click="handleFileChange" color="success" >Convert File</v-btn>
                            <!-- <div v-if="convertedWord">
                                <a :href="convertedWord" download="converted.docx">Download Converted Word</a>
                            </div> -->
                        </v-card-actions>
                    </v-card-text> 
                </v-card> 
            <!--
                <v-card >
                    <v-card-title>
                        <h1 class="display">Extract images from  PDF </h1>
                    </v-card-title>
                    <v-card-text>
                        <v-form>
                            <p>  <input type="file" @change="handleFileUpload" accept=".pdf" name="file1"> </p>
                        </v-form>
                        <v-card-actions>
                            <v-btn v-on:click="convertFile" color="success" >Convert File</v-btn>
                        </v-card-actions>
                    </v-card-text> 
                </v-card>
            -->
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { PDFDocument /*, StandardFonts*/ } from 'pdf-lib';
import { createWorker, PSM } from 'tesseract.js';
// import pdfjsLib from 'pdfjs-dist';
//import { Packer } from 'docx';
//import firebase from 'firebase/app';
import 'firebase/auth';
//import axios from 'axios'

    


export default {
    name: 'PDF',
    data: () => {
        return {
            file1: null,
            file2: null,  
            convertedWord: null,
            ocr: null
        }
    },
    methods: {
        async handleFileUpload(event) {
        
            console.log("Event")
            console.log(event)
        const file = event.target.files[0];
        if (!file) {
            return;
        }
        if (event.target.name === 'file1') {
            this.file1 = file;
        } else {
            this.file2 = file;
        }
        },
        async combineFiles() {
            console.log("Combine files")
            if (!this.file1 || !this.file2) {
                return;
            }
            console.log(this.file1)
            console.log(this.file2)
            const file1Data = await this.readFileData(this.file1);
            const file2Data = await this.readFileData(this.file2);
            const combinedPDFData = await this.createCombinedPDF(file1Data, file2Data);
            this.downloadPDF(combinedPDFData);
        },
        async convertFile() {
            try{
                console.log("Convert files")
                if (!this.file1 ) {
                    return;
                }
                console.log(this.file1)
                const file1Data = await this.readFileData(this.file1);
                const pdfDoc = await PDFDocument.load(file1Data);
                console.log(pdfDoc)
                const pages = pdfDoc.getPages()
                pages.forEach(page => {
                    console.log(page)
                })
            } catch (error) {
                console.error('Error extracting images from PDF:', error);
            }

            // const file1Data = await this.readFileData(this.file1);
            // const pdfDoc = await PDFDocument.load(file1Data);
            // for (let i = 1 ; i <= pdfDoc.getPageCount() ; i++){
            //    // const page = pdfDoc.getPage(i).performOCR();
                
            // }
            // // this.downloadDocx(pdfDoc.saveAsBase64()) 
            // } catch ( error ) {
            //     console.log(error)
            // }
        },
        async readFileData(file) {
            return await new Promise((resolve) => {
                const reader = new FileReader();
                reader.onload = () => {
                    resolve(reader.result);
                };
                reader.readAsArrayBuffer(file);
            });
        },
        async createCombinedPDF(file1Data, file2Data) {
            const pdfDoc = await PDFDocument.create();
            const [file1, file2] = await Promise.all([
                PDFDocument.load(file1Data),
                PDFDocument.load(file2Data),
            ]);
            const pages1 = await pdfDoc.copyPages(file1, file1.getPageIndices());
            const pages2 = await pdfDoc.copyPages(file2, file2.getPageIndices());
            pages1.forEach(page => {
                pdfDoc.addPage(page)
            });
            pages2.forEach(page => {
                pdfDoc.addPage(page)
            });
            const pdfBytes = await pdfDoc.save();
            return pdfBytes;
        },
        
        async handleFileChange() {

            const file1Data = await this.readFileData(this.file1);
       
            this.performOCR(file1Data);
        },

        async performOCR(file) {
            const worker = await createWorker('eng');
            await worker.setParameters({ tessedit_pageseg_mode: PSM.PSM_AUTO });
            const { data: { text } } = await worker.recognize(file);
            console.log(text);
            this.ocr = text;
            console.log('PDF OCR result:', this.ocr);
            await worker.terminate();
        },
        downloadPDF(pdfBytes) {
            console.log("download file")
            const blob = new Blob([pdfBytes], { type: 'application/pdf' });
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = 'combined.pdf';
            link.click();
            window.URL.revokeObjectURL(link.href);
        },

        downloadDocx(wordBytes) {
            console.log("download docx file")
            const blob = new Blob([wordBytes], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' });
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = 'converted.docx';
            link.click();
            window.URL.revokeObjectURL(link.href);
        }
    }
}

</script>


