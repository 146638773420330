<template>
  <v-container>
    <v-spacer />
    <v-row no-gutters>
      <v-col  cols="12" xl="12" lg="12" md="12" sm="12" sx="12">
        <v-card>
          <v-card-title>Terms Of Use</v-card-title>
          <v-card-text style="text-align: justify">
            <FONT SIZE=3 STYLE="font-size: 12pt"><U>Terms of Use</U> </FONT>
            <P >
              <FONT SIZE=2 STYLE="font-size: 9pt">
                The following sets out our standard terms and conditions covering the use of our
                Website and provision of services available via our Website. For any
                queries regarding the use of our Website or provision of our
                services, please contact us by email: info@aston-dive.com
              </FONT>
            </P><br/>
            <P>
              <FONT SIZE=3 STYLE="font-size: 12pt">
                <U>Our company details are</U>
              </FONT>
            </P>
            <P style="margin-left: 20px;">
              <FONT SIZE=2 STYLE="font-size: 9pt">
                <ul >
                <li>Aston-Dive Legal Services is a trading name of: ASTON-DIVE CONSULTANTS LIMITED of
                Delta House, 16 Bridge Road, Haywards Heath, West Sussex, RH16 1UA (&ldquo;the Company&rdquo;)</li>
                <li>Company Registration Number (Registered in England): 12906621</li>
              </ul>
              </FONT>
            </P><br/>
            <P>
              <FONT SIZE=3 STYLE="font-size: 12pt">
                <U>Introduction</U>
              </FONT>
            </P>
            <p>
              <FONT SIZE=2 STYLE="font-size: 9pt">
                <p>These Conditions as amended by us from time to time apply to the use of
                this website https://www.astondive.co.uk (&ldquo;the Website&rdquo;)
                and by accessing the Website and/or obtaining a quote and/or
                instructing a Service you agree to be bound by the terms and
                conditions set out below. If you do not agree to be bound by these
                Conditions you may not use or access the Website.
                </p><br/>
                <p>The Company is
                concerned with the protection of your privacy and this applies to the
                handling of your data during and after your visit to the Website.
                Please see our Privacy Policy on maintaining your privacy and the
                safe handling of your data.
              </p><br/><p>All
                descriptive matter, specifications, advertising and promotional
                material issued by the Company and any descriptions or illustrations
                contained on the Website are published for the sole purpose of giving
                an approximate idea of the services and/or information about third
                parties described in them, and shall not form part of the Agreement.
              </p><br/>
                In this Agreement:<br/>
                <p CLASS="western" style="margin-left: 20px;">
                  <ul>
                    <li>the singular shall include the plural and vice versa</li>
                    <li>any phrase introduced by the words &ldquo;include&rdquo;, &ldquo;including&rdquo;,
                      &ldquo;in particular&rdquo;, &ldquo;excluding&rdquo; or the like shall be construed as 
                      illustrative and shall not limit the sense of the words preceding those terms;
                    </li>
                    <li>any reference to a &ldquo;Clause&rdquo; means a clause of these Conditions;</li>
                    <li>any reference to a person shall be construed as a reference to any person, corporate entity, or any association or partnership (whether or not having separate legal personality) or one or more of the same;</li>
                    <li>the headings are included for convenience only and may not be used in construing or interpreting these Conditions.</li>
                  </ul>
                </p>
              </FONT>
            </p><br/>
                
            <P CLASS="western"><FONT SIZE=3 STYLE="font-size: 12pt"><U>Definitions</U><br/></FONT></P>
            <P CLASS="western"><FONT SIZE=2 STYLE="font-size: 9pt">  
            In these Conditions
            the following words and expressions shall have the following meanings
            when they start with capital letters:</FONT></P>
            <table>  
              <P CLASS="western">
              <FONT SIZE=2 STYLE="font-size: 9pt">
                <tr>
                  <td>&ldquo;<b>Conditions</b>&rdquo; </td>
                  <td>means these terms and conditions.</td>
                </tr>
                <tr>
                  <td>&ldquo;<b>Service&nbsp;or&nbsp;Product</b>&rdquo; </td>
                  <td>means a service or product displayed for sale on the Website including but not limited to conveyancing and other legal services</td>
                </tr>
                <tr>
                  <td>&ldquo;<b>Service&nbsp;or&nbsp;Product&nbsp;Description</b>&rdquo; </td>
                  <td>means that part of the Website where certain terms and conditions in respect of the individual Service or Product are provided.</td>
                </tr>
                <tr>
                  <td>&ldquo;<b>Instruction/s</b>&rdquo;</td>
                  <td>means the placing of an order for a Service or Product made available on the Website.</td>
                </tr>  
                <tr>
                  <td>&ldquo;<b>Users</b>&rdquo;</td>
                  <td>means the users of the Website..</td>
                </tr>  
                <tr>
                  <td>&ldquo;<b>Personal Information</b>&rdquo;</td>
                  <td>means the details provided by you on acquiring a quote or instructing a Service or Product via our Website.</td>
                </tr>  
                <tr>
                  <td>&ldquo;<b>Website</b>&rdquo;</td>
                  <td>means the website located at https://www.astondive.co.uk or any subsequent URL which may replace it.</td>
                </tr>  
                <tr>
                  <td>&ldquo;<b>United Kingdom</b>&rdquo;</td>
                  <td>means England, Wales, Scotland, Northern Ireland and the Channel Islands.</td>
                </tr>  
                <tr>
                  <td>&ldquo;<b>Service Provider</b>&rdquo;</td>
                  <td>means the a solicitor, a notary public or property lawyer practice or firm or other third party practice or firm introduced to the Client the Company.</td>
                </tr>  
                <tr>
                  <td>&ldquo;<b>Client</b>&rdquo;</td>
                  <td>means the person identified in a Quote who will be introduced to one or more Service Provider(s).</td>
                </tr>                  
                <tr>
                  <td>&ldquo;<b>Quote</b>&rdquo;</td>
                  <td>means a written quotation supplied by the Company (in electronic format or via our Website or by email or in hard copy) to a Client setting out the Service or Product requested by the Client and an indicative fee for the Services based on the information supplied to the Company by the Client.</td>
                </tr>                  
                <tr>
                  <td>&ldquo;<b>Searches</b>&rdquo;</td>
                  <td>means the third party enquiries made of third party authorities that include but are not limited to searches made of the local authority, Land Registry, company providing private or personal search services, water authorities etc.</td>
                </tr>                  
                <tr>
                  <td>&ldquo;<b>Agreement</b>&rdquo;</td>
                  <td>as defined in the Introduction.</td>
                </tr>                  
              </FONT>
            </P>
          </table>
          <br/>
          <P CLASS="western">
            <FONT SIZE=3 STYLE="font-size: 12pt">
              <U>General</U><br/></FONT></P>
            <P CLASS="western">
              <FONT SIZE=2 STYLE="font-size: 9pt">
                The Website is provided by the Company referred to in these Conditions as
                &ldquo;we&rdquo; &ldquo;us&rdquo; and/or &ldquo;our&rdquo; and &ldquo;you&rdquo;
                or &ldquo;your&rdquo; shall mean the User and/or Client. If you are
                accessing or using this Website in your capacity as an employee,
                director, officer, partner or agent of a corporate or unincorporated
                entity &ldquo;you&rdquo; and &ldquo;your&rdquo; shall refer to you
                and such entity and you represent that you are authorised to accept
                these Conditions on behalf of such entity and agree to be personally
                bound by these Conditions.
              </FONT>
            </P>
            <br/>
            <P CLASS="western">
              <FONT SIZE=3 STYLE="font-size: 12pt">
                <U>Assignment and sub-contracting</U> 
              </FONT>
            </P>
            <P CLASS="western">
              <FONT SIZE=2 STYLE="font-size: 9pt">
                The Company may assign, sub-contract or otherwise transfer its rights and
                obligations under this Agreement to another person, and will always
                notify the Client in writing if this happens, but this will not
                affect the Client&rsquo;s rights or obligations under this Agreement.
                <br/>
                These Conditions, the Agreement, all matters regarding the interpretation
                or enforcement of it (including non-contractual disputes or claims),
                and any other matters or disputes arising in connection with it or
                its subject matter, shall be governed by English law and the parties
                hereby submit to the non-exclusive jurisdiction of the English courts
                (where the Client is a consumer) and the exclusive jurisdiction of
                the English courts (where the Client is not a consumer).
              </FONT>
            </P>
            <br/>
            <P CLASS="western">
              <FONT SIZE=3 STYLE="font-size: 12pt">
                <U>Use of the Website</U> 
              </FONT>
            </P>
            <P CLASS="western">
              <FONT SIZE=2 STYLE="font-size: 9pt">
                You are provided with access to this Website in
                accordance with these Conditions and any instructions for a Service
                or Product placed by you must be placed strictly in accordance with
                these Conditions.
                <br/> 
                We cannot guarantee that this Website and the content contained herein will
                operate in accordance with your expectations or will be error free.
                If you are aware of any error on this Website please contact us by
                email at info@aston-dive.com and we will endeavour to correct it.
                <br/> 
                It is our
                policy to virus check documents and files before they are posted on
                this Website. However, we cannot guarantee that documents or files
                downloaded from this Website will be free from viruses and we do not
                accept any responsibility for any damage or loss caused by any virus.
                Accordingly, for your own protection, you must use virus-checking
                software when using this Website. You must not post or provide to us
                via this Website, any document or file which you believe may contain
                a virus. You must virus check any document or file which you intend
                to post or provide to us via this Website.
                <br/> 
                You may only
                use this Website for lawful purposes. You must ensure that any
                document, file or other information that you intend to post to our
                Website or provide to us via this Website does not contravene any
                applicable laws or contravene any person&rsquo;s legal rights and you
                must not post or upload anything indecent, obscene, abusive,
                libellous or defamatory. We do not monitor or edit documents or files
                posted or provided to us by other persons for posting on this Website
                and accordingly we do not accept any responsibility for any damage or
                loss you may suffer. We reserve the right to remove material from
                this Website that infringes these rules.
                <br/> 
                We do not
                guarantee uninterrupted and/or reliable access to the site and make
                no guarantees whatsoever as to its operation, functionality or
                otherwise.
              </FONT>
            </P>
            <br/>
            <P CLASS="western">
              <FONT SIZE=3 STYLE="font-size: 12pt">
                <U>Copyright protection, intellectual property and accuracy of the site&rsquo;s content</U> 
              </FONT>
            </P>
            <P CLASS="western">
              <FONT SIZE=2 STYLE="font-size: 9pt">
                We are the owner or the licensee of all intellectual
            property rights and data in the site and in all the material
            published on it. These rights are protected by laws and treaties
            around the world including, without limitation, copyright, design
            rights, database rights and trademark laws. You do not have any
            rights to use the content of the site or such intellectual property
            or data except as expressly set out in these Conditions.</FONT></P>
            <P CLASS="western"><FONT SIZE=2 STYLE="font-size: 9pt">The
            information contained in the Website has been published in good faith
            and with the aim of ensuring its accuracy, but in some cases it may
            be incorrect, incomplete or out of date. If we become aware of any
            material inaccuracies in the information on the site we will use
            reasonable efforts to correct it.</FONT></P>
            <P CLASS="western"><FONT SIZE=2 STYLE="font-size: 9pt">Nothing in
            this Website or the documents available through it constitutes legal
            or other professional advice. You should not rely on any information
            contained in this Website as if it were legal or other professional
            advice. </FONT>
            </P>
            <P CLASS="western">
              <FONT SIZE=2 STYLE="font-size: 9pt">
                Neither ASTON-DIVE CONSULTANTS LIMITED nor Aston-Dive Legal Services are a
                Service Provider and we do not provide any legal services or hold
                ourselves out as doing so. Any legal services offered or provided
                through our Website are offered or provided by a Service Provider who
                is regulated by the Solicitors Regulation Authority for legal
                services conducted by a solicitor, or by the Faculty Office for legal
                services conducted by a notary public. Each of these Service
                Providers is solely responsible for the advice which they provide to
                you and have their own professional liability insurance.
                <br/>
                Nothing in these Conditions limits our liability for death or personal injury
                arising from our negligence, nor our liability for fraud, fraudulent
                misrepresentation, nor any other liability which cannot be excluded
                or limited under applicable law.
                <br/>
                Aston-Dive Legal Services is a trademark of ASTON-DIVE CONSULTANTS LIMITED.
                Except where permitted by applicable law, these Conditions, or
                otherwise set out on the Website, you may not use such trademarks
                without our prior written permission.
              </FONT>
            </P><br/>
            <P CLASS="western">
              <FONT SIZE=3 STYLE="font-size: 12pt">
                <U>Acceptable Use</U>
              </FONT>
            </P>
            <P CLASS="western"><FONT SIZE=2 STYLE="font-size: 9pt">
                You may use the Website only for lawful purposes. Inparticular you shall not:
              </FONT>
            </P>
            <P CLASS="western" style="margin-left: 20px;">
              <FONT SIZE=2 STYLE="font-size: 9pt">
                <ul>
                  <li>monitor/access the Website or any of the Website&rsquo;s content other than by using the Website&rsquo;s navigational structure or for 
                    any purpose other than that expressly permitted in these Conditions;</li>
                  <li>do anything which places an unreasonably large load on the Website, or its supporting computer systems;</li>
                  <li>attempt to disrupt or interfere with the Website in anyway or with another person&rsquo;s use of the Website (including monitoring any aspect of such use), or use the Website as a means of disrupting or interfering with other websites</li>
                  <li>crawl, scrape, probe, scan or test the vulnerability of the Website;</li>
                  <li>use or attempt to use any device to obtain personal data and/or information through the Website;</li>
                  <li>use the Website to receive, access or transmit material that promotes violence, is unauthorised, unlawful, inflammatory, obscene, sexually explicit, pornographic, defamatory, hateful, threatening, degrading, racist, deceptive, in breach of confidence or in breach of third party intellectual property rights (including, without limitation, copyright, trademarks and database rights) or otherwise objectionable or unlawful</li>
                  <li>promote discrimination based on race, sex, religion, nationality, disability, sexual orientation or age;</li>
                  <li>be threatening, abuse or invade another&rsquo;s privacy, or cause annoyance, inconvenience or needless anxiety;</li>
                  <li>harass, upset, embarrass, alarm or annoy any other person;</li>
                  <li>insert or knowingly or recklessly transmit or distribute a virus into our network and computer systems so as to cause harm to the Website, us or other users or which is likely to bring the Website or us into disrepute;</li>
                  <li>impersonate any person, or entity or misrepresent your affiliation with any person or entity;</li>
                  <li>>promote any illegal activity;</li>
                  <li>breach any legal duty owed to a third party, such as a contractual duty or a duty of confidence;</li>
                  <li>assist, encourage or permit any other person to do any of the acts described above.</li>
                  <li>not to reproduce, duplicate, copy or re-sell any part of the Website in contravention of the provisions of these Conditions</li>
                  <li>not to access without authority, interfere with, damage or disrupt: others&rsquo; use of the Website;</li>
                  <li>any part of the Website:</li>
                  <li>any equipment or network on which the Website is stored;</li>
                  <li>any software used in the provision of the Website;</li>
                  <li>any equipment or network or software owned or used by any third party.</li> 
                </ul>              
              </FONT>
            </P>
            <P CLASS="western">
              <FONT SIZE=2 STYLE="font-size: 9pt">
                All the above are forbidden regardless of the means used, including hacking or by the
                  introduction of any worms, trojans, virus or other software. Wilfully
                  corrupt any data, documents or material available on the
                  Website<br/>
                </FONT>
              </P>
          
              <br/>
            <P CLASS="western">
              <FONT SIZE=3 STYLE="font-size: 12pt">
                <U>Accuracy of information and Service or Product Quotes</U> 
              </FONT>
            </P>
            <P CLASS="western">
              <FONT SIZE=2 STYLE="font-size: 9pt">
                You warrant that:</FONT>
            </P>
            <P CLASS="western"  style="margin-left: 20px;">
              <FONT SIZE=2 STYLE="font-size: 9pt">
                <ul>
                  <li>The Personal Information which you are required to provide when you obtain a Quote or make an Instruction as a Client is true, accurate, current and complete in all respects.</li>
                  <li>You understand that the Company's provision of accurate Quotes, introduction of Service Providers and performance of its obligations under this Agreement is dependent on the same.</li>
                  <li>If you provide information which is incomplete, inaccurate or out of date, you understand that: 
                    <ul  style="margin-left: 40px;">
                      <li>any Quote provided by the Company may be inaccurate.</li>
                      <li>any Service Provider introduced to a Client may not be capable of, available to, or willing to perform the Services referred to in the Quote.</li>
                      <li>The Company may not be able to comply with its obligations under this Agreement where it uses or relies on such information.</li>
                    </ul>
                  </li>
                  <li>You agree not to impersonate any other person or entity or to use a false name or a name that you are not authorised to use.</li>
                </ul>
              </FONT>
            </P>
            <br/>
            <P CLASS="western">
              <FONT SIZE=3 STYLE="font-size: 12pt">
                <U>Indemnity</U> 
              </FONT>
            </P>
            <P CLASS="western">
              <FONT SIZE=2 STYLE="font-size: 9pt">
                You
            agree fully to indemnify, defend and hold us, and our officers,
            directors, employees, agents and suppliers, harmless immediately on
            demand, from and against all claims, liability, damages, losses,
            costs and expenses, including reasonable legal fees, arising out of
            any breach of the Conditions by you or any other liabilities arising
            out of your use of this Website.</FONT></P>
            <br/>
            <P CLASS="western">
              <FONT SIZE=3 STYLE="font-size: 12pt">
                <U>Provision of Quotes:</U> 
              </FONT>
            </P>
            <P CLASS="western">
              <FONT SIZE=2 STYLE="font-size: 9pt">
                You acknowledge that a Quote provided by the Company is not an offer
                capable of acceptance and it is a merely a quick calculator to give
                you an idea of a price. A formal quote for legal services will be
                supplied to you by Service Provider. Quotes are valid for 7 calendar
                days from the date the quote was obtained. Quotes are only valid for
                properties in England and Wales.
              </FONT></P>
            <br/>
            <P CLASS="western">
              <FONT SIZE=3 STYLE="font-size: 12pt">
                <U>Fixed Fee Conveyancings</U>
              </FONT>
            </P>
            <P CLASS="western">
              <FONT SIZE=2 STYLE="font-size: 9pt">
                Wherever possible all Services and Products made
                available through our Website are quoted with a Fixed Fee
                Conveyancing in respect of the Service or Product offered. However,
                there may be circumstances that mean that Service Providers are
                unable to provide services on a Fixed Fee basis and you will be
                advised of this by Service Provider. 
              </FONT>
            </P>
            <br/>
            <P CLASS="western">
              <FONT SIZE=3 STYLE="font-size: 12pt">
                <U>Other terms</U> 
              </FONT>
            </P>
            <P CLASS="western">
              <FONT SIZE=2 STYLE="font-size: 9pt">
                The Company does not provide Services and Products itself and does not agree to
                do so through the Agreement; the Agreement relates solely to the
                provision of processing services and not the Services and Products
                performed by Service Providers.<br/>The Client is solely responsible
                for assessing the suitability of any Service Provider to meet the
                Client&rsquo;s requirements (or those of its clients) and the
                acceptability of a Service Provider&rsquo;s terms and conditions for
                the performance of Services and Products;<br/>The Company shall in no
                circumstances be responsible for the performance of, failure to
                perform, or the quality of any Services or Products performed by a
                Service Provider.
              </FONT>
            </P>
            <br/>
            <P CLASS="western">
              <FONT SIZE=3 STYLE="font-size: 12pt">
                <U>Our Rights</U> 
              </FONT>
            </P>
            <P CLASS="western">
              <FONT SIZE=2 STYLE="font-size: 9pt">
                We reserve the right to:
              </FONT></P>
            <P CLASS="western" STYLE="margin-left: 20px; ">
              <FONT SIZE=2 STYLE="font-size: 9pt">
                <ul>
                  <li>modify or withdraw, temporarily or permanently, the Website (or any part
                thereof) with or without notice to you and you confirm that we shall
                not be liable to you or any third party for any modification to or
                withdrawal of the Website; and/or</li>
                  <li>change the Conditions from time
                to time, and your continued use of the Website (or any part thereof)
                following such change shall be deemed to be your acceptance of such
                change.</li>
              <li>It is your responsibility to check regularly to determine
                whether the Conditions have been changed. If you do not agree to any
                change to the Conditions then you must immediately stop using the
                Website.</li>
              </ul>
              </FONT>
            </P>
            <br/>
            <P CLASS="western">
              <FONT SIZE=3 STYLE="font-size: 12pt">
                <U>Third party links</U> 
              </FONT>
            </P>
            <P CLASS="western">
              <FONT SIZE=2 STYLE="font-size: 9pt">
                To provide increased value to our Users, we may provide
                links to other websites or resources for you to access at your sole
                discretion. You acknowledge and agree that, as you have chosen to
                enter the linked Website we are not responsible for the availability
                of such external sites or resources, and do not review or endorse and
                are not responsible or liable, directly or indirectly, for (i) the
                privacy practices of such websites, (ii) the content of such
                websites, including (without limitation) any advertising, content,
                products, goods or other materials or services on or available from
                such websites or resources or (iii) the use to which others make of
                these websites or resources, nor for any damage, loss or offence
                caused or alleged to be caused by, or in connection with, the use of
                or reliance on any such advertising, content, products, goods or
                other materials or services available on such external websites or
                resources.
              </FONT>
            </P>
            <br/>
            <P CLASS="western">
              <FONT SIZE=3 STYLE="font-size: 12pt">
                <U>Offers and codes</U>
              </FONT>
            </P>
            <P CLASS="western">
              <FONT SIZE=2 STYLE="font-size: 9pt">
                From time to time, the Company may offer a promotion.
                You can see the terms and conditions of promotions on our website.
              </FONT>
            </P>
            <P CLASS="western" STYLE="margin-bottom: 0cm"><br/> </P>
          </v-card-text>
          <v-card-actions>
              <v-btn text  @click="back" >  Back </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<style scoped>
a:link {
  text-decoration: none;
}
</style>


<script>


  export default {
 
    methods: {
      back: function () {
        this.$router.go(-1);
      },
    },
  }
</script>
