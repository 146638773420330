<template>
  <v-container>
    <v-spacer />
    <v-row no-gutters>
      <v-col v-for="product in products" :key="product" cols="12" xl="12" lg="12" md="12" sm="12" sx="12">
        <v-card  height="100%" class="pa-3" flat tile>
          <!-- <v-parallax v-if="product.image != null" height="300" max-height="50%"  :src="product.image" ></v-parallax> -->
          <v-card-text class="text--primary" >
            <div><H1> {{product.title}}</H1></div>
            <br/>
            <div>
              <p style="text-align: justify" > 
              <span v-html="product.description"></span>
              </p>
            </div>
            <div>
              <v-row no-gutters  style="px-0 ma-0 pt-0 pb-2">
                <v-col v-for="action in product.actions" :key="action" cols="12" xl="6" lg="6" md="6" sm="12" sx="12">
                  <v-card class="py-0 px-0 ma-0 pa-0" flat tile > 
                    <v-card-actions>
                      <v-row no-gutters style="px-0 ma-0 pt-0 pb-2"  >
                        <v-btn text class="pa-n5 ma-n0" color="deep-purple accent-4" style="background-color: silver;" outlined :to=action.route>
                          {{ action.title }}
                        </v-btn>
                      </v-row>
                    </v-card-actions>
                  </v-card>
                </v-col>
              </v-row>
            </div>
          </v-card-text>
          <v-card-actions bottom  class="ma-0 py-0 " style="position: absolute; bottom: -10px;" >
            <div v-for="button in product.buttons" :key="button">
              <v-chip class="ma-2" 
                  :text-color=getTextColour(button) 
                  :color= getBackgroundColour(button)
                  :href= getLink(button)
                  variant="flat"
                  :prepend-icon=getIcon(button)>
                  {{getText(button)}}
              </v-chip>
            </div> 
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<style scoped>
a:link {
  text-decoration: none;
}
</style>


<script>


  export default {
     data: () => ({
        products: [ 
        {
          title: "Complaints Procedure",
          column: 1,
          description:"<style> .tab { margin-left: 20px; } </style><p style=\"text-align: justify;\""+
                      "<H1 CLASS=\"western\"><FONT SIZE=3>ASTON-DIVE CONSULTANTS LIMITED Complaints Procedure </FONT></H1>"+
                      "<P CLASS=\"western\">If you have a complaint about ASTON-DIVE LEGAL "+
                      "SERVICES or ASTON-DIVE CONSULTANTS LIMITED where you have suffered -"+
                      "or may suffer - financial loss, anguish or inconvenience, please "+
                      "contact us in writing <br/><br/>"+
                      "Email: <A HREF=\"mailto:info%40astondive.co.uk?subject=Aston-Dive%20Consulting%20Limited%20Complaint\">info@aston-dive.com</A>&nbsp;"+
                      "<br/><br/>We aim to resolve any complaint you have about the service we have given you as quickly as possible.&nbsp;<br/><br/>"+
                      "If you have made the  complaint over the phone or in a meeting, we will set out our "+
                      "understanding of the situation to ensure we have fully understood "+
                      "your situation. Our assessment of the complaint will be based "+
                      "upon a reasonable and adequate examination of the evidence. We will "+
                      "contact you in writing to explain our findings. <br/><br/>Once we have "+
                      "received your complaint will write to you within 7 days to explain "+
                      "how your complaint will be investigated. The investigation "+
                      "should be not more than 28 days after we receive your complaint.<br/><br/>If "+
                      "you instruct Aston-Dive Notary &amp; Co, please "+
                      "note that they have their own complaints policies that apply. "+
                      "</P>",
          actions:[],
          image:null,
          buttons:[]
        }
      ],
    }),
    methods: {
    getImage(imageName){
      const img =  require(imageName)
      return img
    },
    getTextColour(button){
      return button.color
    },
    getBackgroundColour(button){
      return button.background
      
    },
    getIcon(button){
      if('avatar' in button){
        return button.avatar.icon
      } 
    },
    getText(button){
      if('avatar' in button){
        return button.avatar.text + " " + button.avatar.caption
      } else {
        return button.text
      }
    },
    getLink(button){
      if ('href' in button ){
        return button.href
      } else {
        return button.to
      }
    }
  }

}
</script>
