<template>
  <v-app>
    <page-header />
      <v-main>
      <router-view :key="$route.fullPath"/>
      </v-main>
    <page-footer />
  </v-app>
</template>

<script>
import PageHeader from './components/PageHeader.vue'
import PageFooter from './components/PageFooter.vue'

export default {
  name: 'App',
  components:{
    PageHeader,
    PageFooter
  },
  data: () => ({
    //
  }),
}
</script>
