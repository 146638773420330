<template>
  <form class="pa-5">
    <span>
      <v-container v-for="page in pages" :key="page"  fluid class="px-0 py-2" >
        <v-row no-gutters>
          <v-col v-for="product in page.products"  :key="product" :cols=page.layout.cols :xl=page.layout.xl
                                              :lg=page.layout.lg :md=page.layout.md :sm=page.layout.sm :sx=page.layout.sx>
            <v-card  height="100%" class="pa-3" flat tile>
              <v-parallax v-if="size(product.image) > 0" height="300" max-height="50%"  :src="product.image" ></v-parallax>
              <v-card-text class="text--primary" >
                <div v-if="product.title !=null"><h1> {{product.title}}</h1></div>
                <br/>
                <div>
                  <p style="text-align: justify" > 
                  <span v-html="product.description"></span>
                  </p>
                </div>
                <div>
                  <v-row no-gutters  style="px-0 ma-0 pt-0 pb-2">
                    <v-col v-for="action in product.actions"  :key="action" :cols=page.layout.cols :xl=page.layout.xl :lg=page.layout.lg 
                                                              :md=page.layout.md :sm=page.layout.sm :sx=page.layout.sx>
                      <v-card class="py-0 px-0 ma-0 pa-0" flat tile > 
                        <v-card-actions>
                          <v-row no-gutters style="px-0 ma-0 pt-0 pb-2"  >
                            <v-btn text class="pa-n5 ma-n0" color="deep-purple accent-4" style="background-color: silver;" outlined 
                                                            :to=action.route>
                              {{ action.title }}
                            </v-btn>
                          </v-row>
                        </v-card-actions>
                      </v-card>
                    </v-col>
                  </v-row>
                </div>
              </v-card-text>
              <v-card-actions bottom  class="ma-0 py-0 " style="position: absolute; bottom: -10px;" >
                <div v-for="button in product.buttons" :key="button">
                  <v-chip class="ma-2" 
                      :text-color=getTextColour(button) 
                      :color= getBackgroundColour(button)
                      :href= getLink(button)
                      variant="flat"
                      :prepend-icon=getIcon(button)>
                      {{getText(button)}}
                  </v-chip>
                </div> 
                <link rel="stylesheet"
                      href="https://maxcdn.bootstrapcdn.com/font-awesome/4.5.0/css/font-awesome.min.css"> 
                  <a href="https://api.whatsapp.com/send?phone=447588820657&text=Hola%21%20Quisiera%20m%C3%A1s%20informaci%C3%B3n%20sobre%20Varela%202." 
                     class="float" 
                     target="_blank"> 
                      <i class="fa fa-whatsapp my-float"></i>
                  </a>
              </v-card-actions>
            </v-card> 
          </v-col> 
          
          <v-col class="py-0 px-2 ma-0 pa-0"  
                v-for="(field, index) in page.form.fields" 
                        :key="index" :cols=page.layout.cols :xl=page.layout.xl 
                        :lg=page.layout.lg :md=page.layout.md :sm=page.layout.sm 
                        :sx=page.layout.sx>
              <v-text-field v-if="field.type==='TextField'"
                  v-model=page.form.fields[index].value 
                  :label=field.label
                  :error-messages="field.errorDisplayed" 
                  :error="field.errorVisible"
                  @input="check(field.name)"
                ></v-text-field>
                <v-textarea  v-if="field.type=='TextArea'"  
                  v-model=page.form.fields[index].value 
                  :label=field.label 
                  :error-messages="field.errorDisplayed" 
                  :error="field.errorVisible" 
                  @input="check(field.name)"
                ></v-textarea> 
                <v-select v-if="field.type=='Select'" 
                    v-model=page.form.fields[index].value
                    :items=field.items 
                    :label=field.label
                    @click="check(field.name)"
                ></v-select>
            </v-col>
            <v-col  v-if="hasProperty(page.form,'submissions')" cols="12" xl="12" lg="12" md="12" sm="12" sx="12">
              <div id="block_container" v-for="(submission,index) in page.form.submissions" :key="index" >
                <v-btn class="mr-4" @click="submit(submission.name)"> {{ submission.name }} </v-btn>
              </div>
            </v-col>
        </v-row>
      </v-container>
    </span>
  </form>
</template>



<script>

 import { toRef } from 'vue'
  import axios from 'axios'
  let properties
  let api = "https://astondive.co.uk/law/v1/"
  
  export default { 
    props: {
      reference: {
        type: String,
        default: 'Other Services'
      }
    },

    data: () => ({
      errors: new Array(),
      isFormCorrect : true,
      pages : [{
        name:"details",
        layout: {
          cols: 12 ,
          xl: 3,
          lg: 6 ,
          md: 6,
          sm: 8,
          sx: 12
        },
        products: [ 
        {
          title: null,
          column: 1,
          description: "<h2>Aston-Dive Legal Services</h2>Delta House<br/> 16 Bridge Road<br/> Haywards Heath<br/> West Sussex<br/> RH16 1UA<br/> <br/> Email : notary@astondive.co.uk or fill in the form below",
          actions:[],
          image: null,
          buttons:[]
        },
        {
          title: null,
          column: 2,
          description: "<iframe height=\"100%\" width=\"100%\" src=\"https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2302.020526273411!2d-0.10006812387769413!3d51.00929018218746!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48758cac6889402d%3A0x7f81d734dc653651!2sDelta%20House%2C%20Bridge%20Rd%2C%20Haywards%20Heath%20RH16%201UA!5e0!3m2!1sen!2suk!4v1693498231387!5m2!1sen!2suk\" allowfullscreen=\"\" loading=\"lazy\" referrerpolicy=\"no-referrer-when-downgrade\" frameborder=\"0\" scrolling=\"no\" marginheight=\"0\" marginwidth=\"0\"></iframe>",
          actions:[],
          image: null,
          buttons:[]
        },
        {
          title: null,
          column: 3,
          description: "<h2>Aston-Dive Legal Services</h2> Curlytail Bungalow<br/> Adversane Lane<br/> Adversane<br/> West Sussex<br/> RH14 9EG<br/> <br/> Email : notary@astondive.co.uk or fill in the form below",
          actions:[],
          image: null,
          buttons:[]
        },
        {
          title: null,
          column: 4,
          description: " <iframe src=\"https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d627.7844050304492!2d-0.45505773030267505!3d50.99512899823134!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zNTDCsDU5JzQyLjUiTiAwwrAyNycxNS45Ilc!5e0!3m2!1sen!2suk!4v1693509026142!5m2!1sen!2suk\" width=\"100%\" height=\"100%\" style=\"border:0;\" allowfullscreen=\"\" loading=\"lazy\" referrerpolicy=\"no-referrer-when-downgrade\"> </iframe>",
          actions:[],
          image: null,
          buttons:[]
        }],
        form :{
          submissionss : [],
          fields: [],
        }
      },
      {
        name: "form",
        layout: {
          cols: 12 ,
          xl: 6,
          lg: 6 ,
          md: 6,
          sm: 12,
          sx: 12
        },
        products:[],
        form: {
          name :"ContactForm",
          submissions : [{
            form: "ContactForm",
            name: "Submit",
            subject: "Enquiry",
            isCancel: false,
            api : "contact.php",
            fields: ["name","email","phone","service","explain"]
          },
          {
            form: "ContactForm",
            name: "Cancel",
            isCancel: true,
            api : "",
            fields: []
          }],      
          fields: [{
            form: "ContactForm",
            name: 'name',
            label: 'Name',
            value: '',
            regex: "[a-z]",
            error: "Must not be blank, and less thean 100 characters",
            errorDisplayed: "",
            type: "TextField",
            prop: ""
          },
          {
            form: "ContactForm",
            name: "email",
            value: '',
            label: "E-mail",
            error: "Must be a valid eMail address",
            regex: "^\\w+([-+.']\\w+)*@\\w+([-.]\\w+)*\\.\\w+([-.]\\w+)*$",
            type: "TextField",
            errorDisplayed:"",
            prop: ""
          },
          {
            form: "ContactForm",
            name: "phone",
            value: '',
            label: "Phone",
            error: "Must be a valid phone number",
            regex: "(\\+)?([0-9]{3})?[-.\\s]?([0-9]{3})[-.\\s]?([0-9]{4})$",
            type: "TextField",
            errorDisplayed:"",
            prop: ""
          },      
          {
            form: "ContactForm",
            name: "service",
            value: '',
            items: [
              'Notarial Services',
              'Property',
              'Other Services',
            ],
            label: "Legal Service",
            type: "Select",
            prop: "reference"
          },
          {
            form: "ContactForm",
            name: "explain",
            value: '',
            regex: "^[0-9A-Za-z\\/. '-?,.;:()]{1,1000}$",
            label: "Explain what is required",
            type: "TextArea",
            error: "Must not be blank, and less than 1000 characters",
            errorDisplayed:"",
            prop: ""
          }],
        },
      }],
    }),
    computed: {
      message: {
        get : function(){
          return "Name: " + this.name +"<br/> Email: " + this.email + "<br/> Enquiry: " + this.explain +  "<br/> ref:"+this.reference
        }
      },
      subject: {
        get : function(){
          return this.selected + " Enquiry"
        }
      },
    },
    methods: {
      size (obj){
        if (obj === null){
          return 0
        }
        return obj.length 
      },
      hasProperty(obj  ,property){
        if (obj === null){
          return false
        }       
        if (property === null){
          return false
        }       
       
        return property in obj
      },
      getImage(imageName){
        const img =  require(imageName)
        return img
      },
      getTextColour(button){
        return button.color
      },
      getBackgroundColour(button){
        return button.background
        
      },
      getIcon(button){
        if('avatar' in button){
          return button.avatar.icon
        } 
      },
      getText(button){
        if('avatar' in button){
          return button.avatar.text + " " + button.avatar.caption
        } else {
          return button.text
        }
      },
      getLink(button){
        if ('href' in button ){
          return button.href
        } else {
          return button.to
        }
      },
      submit (button) {
        console.log ("Submit"+JSON.stringify(button))
        for (let page of this.pages){
          console.log ("Page"+JSON.stringify(page))
          if (this.hasProperty(page,'submissions')){

            for (let submission of page.submissions){
              console.log(submission)
              if (button === submission.name) {
                if (submission.isCancel) {
                  this.$router.go(-1);
                } else {
                  this.isFormCorrect = this.check("ALL")
                  if (!this.isFormCorrect) {
                    console.log("error in form")
                  } else {
                    axios .post(api+submission.api,{"subject":submission.subject,"message":this.createRequest(page,submission)})
                          .then(function (response){
                            console.log(response);
                          }.bind(this));
                    this.$router.push('/');
                  }
                }
              }
            }
          }
        }
      },
      createRequest(page,submission){
        var request = new Array()
        for (const field of page.fields){
          if (submission.fields.includes(field.name)) {
            const item= { name: field.name, value: field.value}
            request.push(item)
          }
        }  
        console.log(JSON.stringify(request))
        return request;
      },

      check(param){
        let correct  = true
        
        while (this.errors.length > 0) {
          this.errors.pop();
        }
        for(const page of this.pages) {
          if (this.hasProperty(page,'fields')){
            for (const field of page.fields){
            console.log ("Page:"+page.name+" Param: "+param+ " field:"+field)
              if (param === field.name || param === "ALL" ){
                if (field.type === 'TextField' || field.type == 'TextArea' ){
                  if (field.regex != null && field.regex.length > 0){
                    let text = field.value
                    const pattern = RegExp(field.regex);
                    let found = pattern.test(text)
                    if(!found){
                      field.errorDisplayed = field.error;
                      correct = false;
                      this.errors.push(field.error)
                    } else {
                      field.errorDisplayed = "";
                    }
                  }
                } 
              }
            }
          } else {
            
            console.log ("Page:"+page.name+" Param: "+param+ " "+JSON.stringify(page))
          }
        }
        return correct 
      }
    },
    created() {
      let i=0;
      for(const page of this.pages) {
        console.log ("Created :: "+page.name)
        if (this.hasProperty(page,'fields')){
          for (const field of page.fields){
            console.log("param ["+i+"] = "+field.name)
            if (field.prop !== null && field.prop.length > 0){
              page.fields[i].value  = toRef(properties, field.prop).value
            }
            i++;
          }
        }
      }
      if(window.location.host.toLowerCase().includes("localhost")){
        api = "https://astondive.co.uk/law/staging/"
      } else {
        api = "https://astondive.co.uk/law/v1/"
      }
      console.log(api)
    },
    setup (props) {
      properties = props
    },
  }
</script>

<style>
.kanban-board {
    display: flex;
}

.popup {
    overflow-y: scroll;
}

.row {
  display: flex;
}

.quarter-column {
  flex: 25%;
  padding: 2px;
  color: #000;
  background-color: #ffe;
}
.column {
  flex: 50%;
  padding: 2px;
  color: #000;
  background-color: #ffe;
}
.float{
	position:fixed;
	width:60px;
	height:60px;
	bottom:40px;
	right:40px;
	background-color:#25d366;
	color:#FFF;
	border-radius:50px;
	text-align:center;
  font-size:30px;
	box-shadow: 2px 2px 3px #999;
  z-index:100;
}

.my-float{
	margin-top:16px;
}

#block_container {
    display: inline-block;
    vertical-align: middle;
}
</style>