<template>
  <v-main class="my-2 mt-0 pa-0">
    <v-row dense class="my-0 py-0 px-2 mx-0">
      <v-col lg="12" md="12" sm="12"  xs="12">
        <v-card  height="100%">
          <v-card-title>Transaction Options</v-card-title>
          <v-row  class="mx-0 mt-0 mb-0 px-0 pt-0 pb-0" >
            <v-col v-if="buy" cols="12" xl="2" lg="2" md="3" sm="4" xs="6" class="my-0 mx-1 py-0 px-0" >
              <v-text-field dense
                class="my-0 mt-2 py-0 mx-2 px-2"
                label="Purchase Amount"
                clearable
                :rules="[currencyRules]"
                v-model="price"
                prefix="£"> 
              </v-text-field>
            </v-col>
            <v-col v-if="sell" cols="12" xl="2"  lg="2" md="3" sm="4" xs="12" class="my-0 mx-1 py-0 px-0" >
              <v-text-field  dense
                class="my-2 py-0 mx-2 px-2"
                label="Sale Amount"
                clearable
                :rules="[currencyRules]"
                v-model="salePrice"
                prefix="£">
              </v-text-field>
            </v-col>
            <v-col v-if="remortgage" cols="12" xl="2" lg="2" md="3" sm="4" xs="6" class="my-0 mx-1 py-0 px-0" >
              <v-text-field  dense
                class="my-2 py-0 mx-2 px-2"
                label="Property Value"
                clearable
                :rules="[currencyRules]"
                v-model="remortgagePrice"
                prefix="£">
              </v-text-field>
            </v-col>
          </v-row>
          <v-row  class="mx-0 mt-0 mb-0 px-0 pt-0 pb-1" >
            <v-col cols="12" xl="2" lg="2" md="2" sm="3" xs="12" class="my-0 mx-1 py-0 px-0" >
              <v-switch  dense v-model="buy" color="primary" hide-details  label="Buy a Property" > </v-switch>
            </v-col>
            <v-col cols="12" xl="2"  lg="2" md="2" sm="3" xs="12" class="my-0 mx-1 py-0 px-0" >
              <v-switch  dense v-model="sell" color="primary" hide-details label="Sell a Property" > </v-switch>
            </v-col>
            <v-col cols="12" xl="2" lg="2" md="2" sm="3" xs="12" class="my-0 mx-1 py-0 px-0">
              <v-switch dense v-model="remortgage" color="primary" hide-details label="Remortgage Only" > </v-switch>
            </v-col>
          </v-row>            
          <v-divider  v-if="remortgage"  class="pa-1"></v-divider>
          <v-slide-group  v-if="remortgage"  class="pb-2" multiple show-arrows>
            <v-slide-item>
              <v-btn-toggle v-model="leasehold"  rounded dense >
                <v-btn class="mx-1 my-0 pa-2" value="true" selected-class="purple text-white" >
                  <span id="checkboxLabel">Leasehold</span>
                </v-btn>
              </v-btn-toggle>
            </v-slide-item> 
          </v-slide-group>
          <v-divider  v-if="buy" class="pa-1" ></v-divider>
          <v-slide-group  v-if="buy" class="pb-2"  multiple show-arrows>
            <v-slide-item>
              <v-btn-toggle v-model="leasehold"  rounded dense >
                <v-btn class="mx-1 my-0 pa-2" value="true"  selected-class="purple text-white" >
                  <span id="checkboxLabel">Leasehold Purchase</span>
                </v-btn>
              </v-btn-toggle>
            </v-slide-item> 
            <v-slide-item>  
              <v-btn-toggle v-model="mortgage" rounded dense >
              <v-btn class="mx-1 my-0 pa-2" value="true" selected-class="purple text-white">
                <span id="checkboxLabel">Mortgage Required</span>
              </v-btn>
              </v-btn-toggle>
            </v-slide-item>
            <v-slide-item >
              <v-btn-toggle v-model="newBuild" rounded dense > 
              <v-btn class="mx-1 my-0 pa-2" value=true :input-value="newBuild"  selected-class="purple text-white"  >
                <span id="checkboxLabel">New Build</span>
              </v-btn>
              </v-btn-toggle>
            </v-slide-item>
            <v-slide-item>
              <v-btn-toggle v-model="rightToBuy" rounded dense >
                <v-btn class="mx-1 my-0 pa-2" value=true   selected-class="purple text-white" >
                  <span id="checkboxLabel">Right to Buy</span>
                </v-btn>
               </v-btn-toggle>
            </v-slide-item>

            <v-slide-item>
              <v-btn-toggle v-model="helpToBuy" rounded dense >
                <v-btn class="mx-1 my-0 pa-2" value=true selected-class="purple text-white">
                  <span id="checkboxLabel">Help to Buy </span>
                </v-btn>
              </v-btn-toggle>
            </v-slide-item>
          </v-slide-group>
          <v-divider  v-if="sell" class="pa-1" ></v-divider>
          <v-slide-group  v-if="sell"  class="pb-2" multiple show-arrows>
            <v-slide-item>
              <v-btn-toggle v-model="leaseholdSale" rounded dense >
              <v-btn class="mx-1 my-0 pa-2" value=true selected-class="purple text-white">
                <span id="checkboxLabel">Leasehold Sale </span>
              </v-btn>
               </v-btn-toggle>
            </v-slide-item>
            <v-slide-item>
              <v-btn-toggle v-model="mortgageSale" rounded dense >
              <v-btn class="mx-1 my-0 pa-2" value=true selected-class="purple text-white">
                <span id="checkboxLabel">Repay mortgage</span>
              </v-btn>
               </v-btn-toggle>
            </v-slide-item>
            <v-slide-item>
              <v-btn-toggle v-model="sharedOwnership" rounded dense >
              <v-btn class="mx-1 my-0 pa-2" value=true selected-class="purple text-white">
                <span id="checkboxLabel">Shared Ownership </span>
              </v-btn>
               </v-btn-toggle>
            </v-slide-item>
          </v-slide-group>
        </v-card>
      </v-col>
    </v-row>
    <v-row w dense class="my-0 py-0 px-2 mx-0">
      <v-col cols="12" lg="3" md="4" sm="6" sx="12" v-if="buy">
        <v-card class="ma-3 text-center">
          <v-card-title >Stamp Duty</v-card-title>
          <v-card-subtitle>
            <v-row>
              <v-col class="headline text-left"> £ {{ stampduty }}</v-col>
            </v-row>
            <v-row>
              <v-col class="text-left">
                ** Based on purchases with no surcharges
                <div v-if="salesDiscount!==0 ||purchaseDiscount!==0">&nbsp;<br/> </div>
              </v-col>
            </v-row>
          </v-card-subtitle>
        </v-card>
      </v-col>
      <v-col cols="12" lg="3" md="4" sm="6" sx="12 " v-if="sell">
        <v-card class="ma-3 text-xs-center">
          <v-card-title>Sales Fees From</v-card-title>
          <v-card-subtitle>
            <v-row>
              <v-col class="headline text-left"> £ {{ feeFromStore("Sales Fees")-salesDiscount }} + VAT</v-col>
            </v-row>
            <v-row>
              <v-col class="text-left">
                ** Based on assumptions - fees may vary
                <div v-if="salesDiscount!==0">** includes £ {{salesDiscount }} discount</div>
                <div v-if="purchaseDiscount!==0">&nbsp;<br/> </div>
              </v-col>
            </v-row>
          </v-card-subtitle>
        </v-card>
      </v-col>
      <v-col cols="12" lg="3" md="4" sm="6" sx="12" v-if="buy">
        <v-card class="ma-3 text-center">
          <v-card-title> Purchase fees from</v-card-title>
          <v-card-subtitle>
            <v-row>
              <v-col class="headline text-left"> £ {{ feeFromStore("Purchase Fees")-purchaseDiscount }} + VAT</v-col>
            </v-row>
            <v-row>
              <v-col class-="text-left">
                ** Based on assumptions - fees may vary
                <div v-if="purchaseDiscount!==0">** includes £ {{purchaseDiscount }} discount</div>
                <div v-if="salesDiscount!==0"> &nbsp;<br/> </div>
              </v-col>
            </v-row>
          </v-card-subtitle>
       
        </v-card>
      </v-col>
      <v-col cols="12" lg="3" md="4" sm="6" sx="12" v-if="remortgage">
      <v-card class="ma-3 text-xs-center">
        <v-card-title>Remortgage fees from</v-card-title>
          <v-card-subtitle>
            <v-row>
              <v-col class="headline text-left"> £ {{ feeFromStore("Remortgage Fees") }} + VAT</v-col>
            </v-row>
            <v-row>
              <v-col class="text-left">
                ** Based on assumptions - fees may vary
                <div v-if="salesDiscount!==0 ||purchaseDiscount!==0">&nbsp;<br/> </div>
              </v-col>
            </v-row>
          </v-card-subtitle>
        </v-card>
      </v-col>
      <v-col cols="12" lg="12" md="12" sm="12" sx="12" v-if="buy || sell ">
        <v-card>
          <v-card-title>Transaction Breakdown</v-card-title> 
          <v-simple-table >
            <template v-slot:default>
              <thead>
                <tr>
                  <th v-for="header in itemHeader" :key="header.text" :class="header.format"> &nbsp;{{ header.text}} &nbsp;</th>          
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in lineItems" :key="item.name">
                  <td class="text-left px-2  ma-3" >{{ item.name }} &nbsp;&nbsp; </td>
                  <td class="text-right  px-2 ma-3"> &nbsp; {{ item.net }} </td>
                  <td class="text-right  px-2 ma-3 ">&nbsp;{{ item.vat }}</td>
                  <td class="text-right  px-2 ma-3">&nbsp; &nbsp;{{ item.total }}</td>
                </tr>
                <tr v-if="purchaseDiscount!==0 || salesDiscount!==0">
                  <td class="text-left px-2  ma-3" > {{discount.title}} &nbsp;&nbsp; </td>
                  <td class="text-right  px-2 ma-3"> &nbsp; {{ discount.net }} </td>
                  <td class="text-right  px-2 ma-3 ">&nbsp;{{ discount.vat }}</td>
                  <td class="text-right  px-2 ma-3">&nbsp; &nbsp;{{  discount.total }}</td>
                </tr>
                <tr>
                  <td class="text-left font-weight-bold  px-2 ma-3"> Total &nbsp;</td>
                  <td class="text-right font-weight-bold  px-2 ma-3">&nbsp;{{ breakdownTotal.net }}</td>
                  <td class="text-right font-weight-bold  px-2 ma-3">&nbsp;{{ breakdownTotal.vat }}</td>
                  <td class="text-right font-weight-bold  px-2 ma-3">&nbsp;&nbsp;{{ breakdownTotal.total }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table> 
          <v-card-text>  ** Based on assumptions - fees may vary</v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" lg="12" md="12" sm="12" sx="12" v-if="buy || sell ">
        <v-card>   <v-card-text/>

          <v-card-actions bottom  class="mt-12 py-0 " style="position: absolute; bottom: -8px;" >
            <v-chip bottom label color="black" dark  to="../../Content/CONTACT/reference=Property"> Contact Us </v-chip>
            <v-chip  flat :ripple="false" label class="pa-4 ma-1" dark color="primary"  href="tel:+441403588051" >
              <v-avatar left><v-icon>mdi-phone-classic</v-icon></v-avatar>
              <span  class="d-none d-sm-block text-caption">01403-588051</span>     
              <span class="d-sx-flex d-sm-none">Call</span>         
            </v-chip>
          </v-card-actions>
      
        </v-card>
      </v-col>
    </v-row>
  </v-main>
</template>

<script>
import { mapGetters } from "vuex";

export function camelise(str){
  let arr = str.split('-');
  let capital = arr.map(item=> item.charAt(0).toUpperCase() + item.slice(1).toLowerCase());
  let capitalString = capital.join("");

  return capitalString.charAt(0).toLowerCase() + capitalString.slice(1);

}


export function capitalise(str){
  let arr = str.split('-');
  let capital = arr.map(item=> item.charAt(0).toUpperCase() + item.slice(1).toLowerCase());
  let capitalString = capital.join("");

  return capitalString;

}

export function currencyToFloat(currency){ 
  var cstr = currency.toString().trim()
  
  //console.log("currency: [" + cstr +"]");
  var k, temp; 
  // Loop to make substring 
  temp = ''
  //console.log(cstr.length)
  for(var i = 0; i < cstr.length; i++){ 
    k = cstr.charCodeAt(i); 
    if((k === 46 )||(k >47&& k < 58)){ 
      temp = temp + String.fromCharCode(k)
    } 
  } 
  //console.log("currency:" + temp);
  return parseFloat(temp); 
} 

function tierToPrice(array, price, extraFixed=0){
  var rate = 0;
  //console.log (array)
  let i =0

  while ((rate === 0)&&(i <array.length)){
    const low= parseInt(array[i].from)
    const high = parseInt(array[i].to)
    
    if ((price >= low )&&( price <= high)){
      const fixed = parseInt(array[i].fixedRate)
      const variable = parseFloat(array[i].variableRate)
      rate =  fixed + variable  * price + extraFixed
    } 
    i = i + 1 
  }
  return Math.round(rate);
}

function createLineItem(n,c,v,cv) {
  const vv = cv ? v*0.2 : 0
  const tv = cv ? v*1.2 : v
  return {name:n,category:c,net:formatter.format(v),vat:formatter.format(vv),total:formatter.format(tv)}
}

function pickFirstGreaterThan (number=0, one=0, two=0, three=0, literal=0){
  if (one > number){
    return one
  } else if ( two > number) {
    return two
  } else if ( three > number) {
    return three
  }
  return literal
}

export function evaluateNumber(number) {
  if (number === null){
   return -1;  
  } else {
    return (parseInt(number)||0) 
  }
}

const formatter = new Intl.NumberFormat('en', {
  style: 'currency',
  currency: 'GBP',
  minimumFractionDigits: 2
})
export default {
  name: "QuotePage",
  data() {
    return {
      feeFromStore: (fee) => {

        console.log(fee,this.lineItems)
        let value = 0;
        for (let item of this.lineItems){
          console.log(item)
          if ((item.category === "fees") && (item.name === fee)){
            value = value + currencyToFloat(item.net)
          }
        }
        console.log("value=",value)
        return value
      },
      currencyRules: (value) => {
        if (value.length === 0) {
          return true;
        }
        var comma = -1;
        for (var i = value.length - 1; i >= 0; i--) {
          var character = value.charAt(i);
          if (character === "," && comma === -1) {
            comma = 3;
          }
          if (comma != 3) {
            if (comma != -1) {
              comma++;
            }
            if (
              character === "1" ||
              character === "2" ||
              character === "3" ||
              character === "4" ||
              character === "5" ||
              character === "6" ||
              character === "7" ||
              character === "8" ||
              character === "9" ||
              character === "0"
            ) {
              return true;
            } else {
              return false;
            }
          } else {
            return character === ",";
          }
        }
      },
    };
  },
  computed: {
    ...mapGetters({
      buyFromStore: "getBuy",
      helpToBuyFromStore: "getHelpToBuy",
      leaseholdFromStore: "getLeasehold",
      leaseholdSaleFromStore: "getLeaseholdSale",
      mortgageFromStore: "getMortgage",
      mortgageSaleFromStore: "getMortgageSale",
      newBuildFromStore: "getNewBuild",
      getPriceFromStore: "getPrice",
      remortgageFromStore: "getRemortgage",
      getRemortgagePriceFromStore: "getRemortgagePrice",
      rightToBuyFromStore: "getRightToBuy",
      getSalePriceFromStore: "getSalePrice",
      sellFromStore: "getSell",
      sharedOwnershipFromStore: "getSharedOwnership",
      beforeJulyFromStore: "getBeforeJuly",
      purchaseFeeFromStore: "getPurchaseFee",
      remortgageFeeFromStore: "getRemortgageFee",
      landRegistryFromStore: "getLandRegistry",
      stampDutyFromStore: "getStampDuty",
      salesFeeFromStore:"getSalesFee",
      getLines:"getLines"
    }),
    itemHeader : {
      get(){
        var headers = new Array();
        headers.push({text:'Fees', align: 'start',value: 'name', groupable: 'false' , format: "text-left"})
        headers.push({text:'NET', align: 'right',value: 'net', groupable: 'false'  , format: "text-right"})
        headers.push({text:'VAT', align: 'right',value: 'vat', groupable: 'false'  , format: "text-right"})
        headers.push({text:'Total', align: 'right',value: 'total', groupable: 'false'  , format: "text-right"})

        return headers
      }
    },
    discount : {
      get() {
        var v =0.0
        var vv =0.0 
        var t = "No Discount"
        if (this.salesDiscount!==0){
          v = this.salesDiscount
          vv = this.salesDiscount * 0.2
          t = "Sales Discount"
        } else if (this.purchaseDiscount) {
          v = this.purchaseDiscount
          vv = this.purchaseDiscount * 0.2
          t = "Purchase Discount"
        }
        var tv = v + vv 

        return {title:t, net:formatter.format(v ),vat:formatter.format(vv ),total:formatter.format(tv)}
      }
    },
    breakdownTotal :{
      get() {
        var v =0.0
        var vv =0.0
        this.lineItems.map(item => {
          v = v + currencyToFloat(item.net)
          vv= vv + currencyToFloat(item.vat)
        })        

        var tv = v + vv 

        return {net:formatter.format(v),vat:formatter.format(vv),total:formatter.format(tv)}
      }
    },
    lineItems : {
      get() {
          var items = new Array();
          var lineItems = JSON.parse(this.getLines);
          console.log ("=======================================")
          let z =0
          for (const lineItem of lineItems){
            let leaseholdSwitch = ((this.leasehold) ? 1 : 0 )
            let leaseholdSaleSwitch = ((this.leaseholdSale) ? 1 : 0 )
            let itemIsForPurchases = evaluateNumber(lineItem.purchase);
            let itemIsForRemortgage = evaluateNumber(lineItem.remortgage);
            let itemIsForLeaseholdPurchases = evaluateNumber(lineItem.leasehold_purchase)
            let itemIsForSales = evaluateNumber(lineItem.sale) 
            let itemIsForLeaseholdSales =evaluateNumber(lineItem.leasehold_sale)
            let itemIsForMortgage = (parseInt(lineItem.mortgage || -1))
            let itemIsForMortgageRepay = evaluateNumber(lineItem.repay_mortgage)
            let itemIsForSharedOwnership = evaluateNumber(lineItem.shared_ownership)
          
             console.log ("ITEM["+(z++ )+"]" + JSON.stringify(lineItem) )

            var display = true;
            if ( ((itemIsForPurchases === 1) && (!this.buyFromStore))||
                 ((itemIsForPurchases === 0) && (this.buyFromStore ))){
              console.log("display off buy a property")
              display = false;
            } else {  
              //console.log("display ON buy a property")
            }
            if ( ((itemIsForRemortgage === 1) && (!this.remortgageFromStore))||
                 ((itemIsForRemortgage === 0) && (this.remortgageFromStore ))){
              console.log("display off remortgage a property")
              display = false;
            } else {  
              //console.log("display ON buy a property")
            }
            if (((itemIsForSales === 1) && (!this.sellFromStore))||
                 ((itemIsForSales === 0) && (this.sellFromStore))){
              console.log("display off  sellFromStore")
              display = false;
            }
            if (((itemIsForMortgageRepay === 1) && (!this.mortgageSale)) ||
                ((itemIsForMortgageRepay === 0 ) && (this.mortgageSale ) )){
              console.log("display off  mortgageSale")
              display = false;
            }
            if (itemIsForMortgage === 1 && itemIsForPurchases !== 0 && !this.mortgage){
              console.log("display off  mortgage")
              display = false;
            }
            if (((itemIsForLeaseholdPurchases === 1) && (leaseholdSwitch===0)) ||
                ((itemIsForLeaseholdPurchases === 0) && (leaseholdSwitch===1))){
              console.log("display off  leasehold purchase")
              display = false;
            } else {
              //console.log("display ON  leasehold purchase")
            } 
            if (((itemIsForLeaseholdSales === 1) && (leaseholdSaleSwitch===0)) ||
                ((itemIsForLeaseholdSales === 0) && (leaseholdSaleSwitch===1))){
              console.log("display off  leasehold sale")
              display = false;
            } else {
              //console.log("display ON  leasehold sale")
            } 
            if ((parseInt(lineItem.help_to_buy) === 1) && (!this.helpToBuy)){
              console.log("display off  helpToBuy")
              display = false;
            }
            if ((parseInt(lineItem.right_to_buy) === 1) && (!this.rightToBuy)){
              console.log("display off  rightToBuy")
              display = false;
            }
            if (((itemIsForSharedOwnership === 1) && (!this.sharedOwnership)) ||
                ((itemIsForSharedOwnership === 0 ) && (this.sharedOwnership ) )){
              console.log("display off  mortgageSale")
              display = false;
            }
            if ((parseInt(lineItem.New_build) === 1) && (!this.newBuild)){
              console.log("display off  newBuild")
              display = false;
            }
            var value =0
            if (display){
              if (lineItem.type === "TIER") {
                  if (lineItem.element === "purchase-fee"){
                    value = value + this.purchaseFee
                  } else if  (lineItem.element === "sales-fee"){
                    value = value + this.salesFee  
                  } else if  (lineItem.element === "land-registry"){
                    value = value + this.landregistry
                  } else if  (lineItem.element === "stamp-duty"){
                    value = value + this.stampduty
                  } else if  (lineItem.element === "remortgage-fee"){
                    value = value + this.remortgageFee
                  }
              }
              if (lineItem.value !== null){
                value = value + parseFloat(lineItem.value)
              }
              items.push(createLineItem(lineItem.description,
                  lineItem.category,value,parseInt(lineItem.vat)===1))
            }
          }
          return items
  
      }
    },
    priceFromStore : {
      get(){
        return pickFirstGreaterThan(0,
          this.getPriceFromStore,
          this.getSalePriceFromStore,
          this.getRemortgagePriceFromStore,
          100000)
      }
    },
    salePriceFromStore : {
      get(){
        return pickFirstGreaterThan(0,
          this.getSalePriceFromStore,
          this.getPriceFromStore,
          this.getRemortgagePriceFromStore,
          100000)
      }
    },

    remortgagePriceFromStore : {
      get(){
        return pickFirstGreaterThan(0,
          this.getRemortgagePriceFromStore,
          this.getPriceFromStore,
          this.getSalePriceFromStore,
          100000)
      }
    },

    stampduty: {
      get() {
        var rate = 0;
        var price = this.priceFromStore;
        var stampDutyArray = JSON.parse(this.stampDutyFromStore)
        let i =0

        while ((rate === 0)&&(i <4)){
          const low= parseInt(stampDutyArray[i].from)
          const high = parseInt(stampDutyArray[i].to)
          
          if ((price >= low )&&( price <= high)){
            const fixed = parseInt(stampDutyArray[i].fixedRate)
            const variable = parseFloat(stampDutyArray[i].variableRate)
            rate =  fixed + variable  * price
          } 
          i = i + 1 
        }
        return Math.round(rate);
      },
    },
    landregistry: {
      get() {
        return tierToPrice(JSON.parse(this.landRegistryFromStore),this.priceFromStore)
      }
    },
    salesDiscount:{
      get() {
        if (this.buyFromStore && this.sellFromStore) {
          if (this.salesFee <= this.purchaseFee)
             return Math.round(0.10 * this.salesFee);
        } 
        return 0 
      }
    },
    purchaseDiscount:{
      get() {
        if (this.buyFromStore && this.sellFromStore) {
          if (this.salesFee > this.purchaseFee)
            return Math.round(0.10 * this.purchaseFee);
        } 
        return 0 
      }
    },
    salesFee: {
      get() {
        return tierToPrice(
          JSON.parse(this.salesFeeFromStore),
          this.salePriceFromStore) //+ this.leaseholdSaleCost
      },
    },
    purchaseFee: {
      get() {
        return tierToPrice(
          JSON.parse(this.purchaseFeeFromStore),
          this.priceFromStore) //+ this.leaseholdSaleCost
      }
    },
    remortgageFee: {
      get() {
        return tierToPrice(
          JSON.parse(this.remortgageFeeFromStore),
          this.priceFromStore) //+ this.leaseholdSaleCost
      }
    },
    buy: {
      get() {
        return this.buyFromStore;
      },
      set(value) {
        return this.$store.commit("setBuy", value);
      },
    },
    sell: {
      get() {
        return this.sellFromStore;
      },
      set(value) {
        return this.$store.commit("setSell", value);
      },
    },
    leasehold: {
      get() {
        return this.leaseholdFromStore;
      },
      set(value) {
        return this.$store.commit("setLeasehold", value);
      },
    },
    leaseholdSale: {
      get() {
        return this.leaseholdSaleFromStore;
      },
      set(value) {
        return this.$store.commit("setLeaseholdSale", value);
      },
    },
    newBuild: {
      get() {
        //console.log("New build get" +  this.newBuildFromStore)
        if (this.newBuildFromStore === undefined ) {
          return false;
        }
        return this.newBuildFromStore;
      },
      set(value) {
        if (value === undefined){
          value = false
        }
        //console.log("New build set" + value)
        return this.$store.commit("setNewBuild", value);
      },
    },
    helpToBuy: {
      get() {
        //console.log("get helpToBuyFromStore" +  this.helpToBuyFromStore)
        if (this.helpToBuyFromStore === undefined ) {
          return false;
        }
        return this.helpToBuyFromStore;
      },
      set(value) {
        if (value === undefined){
          value = false
        }
        //console.log("setHelpToBuy" + value)
        return this.$store.commit("setHelpToBuy", value);
      },
    },
    rightToBuy: {
      get() {
        if (this.rightToBuyFromStore === undefined ) {
          return false;
        }
        return this.rightToBuyFromStore;
      },
      set(value) {
        if (value === undefined){
          value = false
        }
        return this.$store.commit("setRightToBuy", value);
      },
    },
    sharedOwnership: {
      get() {
        //("sharedOwnershipFromStore get" +  this.sharedOwnershipFromStore)
        if (this.sharedOwnershipFromStore === undefined ) {
          return false;
        }
        return this.sharedOwnershipFromStore;
      },
      set(value) {
        if (value === undefined){
          value = false
        }
        //console.log("setSharedOwnership set" + value)
        return this.$store.commit("setSharedOwnership", value);
      },
    },
    mortgageSale: {
      get() {
        if (this.mortgageSaleFromStore === undefined ) {
          return false;
        }
        return this.mortgageSaleFromStore;
      },
      set(value) {
        if (value === undefined){
          value = false
        }
        return this.$store.commit("setMortgageSale", value);
      },
    },
    mortgage: {
      get() {
        if (this.mortgageFromStore === undefined ) {
          return false;
        }
        return this.mortgageFromStore;
      },
      set(value) {
        if (value === undefined){
          value = false
        }
        return this.$store.commit("setMortgage", value);
      },
    },
    remortgage: {
      get() {
        return this.remortgageFromStore;
      },
      set(value) {
        return this.$store.commit("setRemortgage", value);
      },
    },    
    price: {
      get() {
        var output = "";
        var value = this.priceFromStore.toString();
        var comma = 0;
        for (var i = value.length - 1; i >= 0; i--) {
          var character = value.charAt(i);
          if (
            character === "1" ||
            character === "2" ||
            character === "3" ||
            character === "4" ||
            character === "5" ||
            character === "6" ||
            character === "7" ||
            character === "8" ||
            character === "9" ||
            character === "0"
          ) {
            if (comma === 3) {
              output = "," + output;
              comma = 0;
            }
            output = character + output;
            comma++;
          }
        }
        return output;
      },
      set(value) {
        var output = "";
        for (var i = 0; i < value.length; i++) {
          var character = value.charAt(i);
          if (
            character === "1" ||
            character === "2" ||
            character === "3" ||
            character === "4" ||
            character === "5" ||
            character === "6" ||
            character === "7" ||
            character === "8" ||
            character === "9" ||
            character === "0"
          ) {
            output = output + character;
          }
        }
        return this.$store.commit("setPrice", parseInt(output));
      },
    },
    salePrice: {
      get() {
        var output = "";
        var value = this.salePriceFromStore.toString();
        var comma = 0;
        for (var i = value.length - 1; i >= 0; i--) {
          var character = value.charAt(i);
          if (
            character === "1" ||
            character === "2" ||
            character === "3" ||
            character === "4" ||
            character === "5" ||
            character === "6" ||
            character === "7" ||
            character === "8" ||
            character === "9" ||
            character === "0"
          ) {
            if (comma === 3) {
              output = "," + output;
              comma = 0;
            }
            output = character + output;
            comma++;
          }
        }
        return output;
      },
      set(value) {
        var output = "";
        for (var i = 0; i < value.length; i++) {
          var character = value.charAt(i);
          if (
            character === "1" ||
            character === "2" ||
            character === "3" ||
            character === "4" ||
            character === "5" ||
            character === "6" ||
            character === "7" ||
            character === "8" ||
            character === "9" ||
            character === "0"
          ) {
            output = output + character;
          }
        }
        return this.$store.commit("setSalePrice", parseInt(output));
      },
    }, 
    remortgagePrice: {
      get() {
        var output = "";
        var value = this.remortgagePriceFromStore.toString();
        var comma = 0;
        for (var i = value.length - 1; i >= 0; i--) {
          var character = value.charAt(i);
          if (
            character === "1" ||
            character === "2" ||
            character === "3" ||
            character === "4" ||
            character === "5" ||
            character === "6" ||
            character === "7" ||
            character === "8" ||
            character === "9" ||
            character === "0"
          ) {
            if (comma === 3) {
              output = "," + output;
              comma = 0;
            }
            output = character + output;
            comma++;
          }
        }
        return output;
      },
      set(value) {
        var output = "";
        for (var i = 0; i < value.length; i++) {
          var character = value.charAt(i);
          if (
            character === "1" ||
            character === "2" ||
            character === "3" ||
            character === "4" ||
            character === "5" ||
            character === "6" ||
            character === "7" ||
            character === "8" ||
            character === "9" ||
            character === "0"
          ) {
            output = output + character;
          }
        }
        return this.$store.commit("setRemortgagePrice", parseInt(output));
      },
    },
  },
  created() {
    this.$store.commit("setBuy", true);
    this.$store.commit("setSell", false);
    this.$store.commit("setLeasehold", false);
    this.$store.commit("setPrice", 0);
    this.$store.commit("setSalePrice", 0);
    this.$store.commit("setRemortgagePrice", 0);

    const urlParams = window.location.href.substring(window.location.href.indexOf("#")+8).split("/");
    const result = getContentByPageName(urlParams)
    const data = getDataByPageName(urlParams)
    var content = JSON.parse(data)
    this.$store.commit("setTiers",JSON.stringify(content.tiers))
    for (const key in content.tiers){
      const storeKey = "set"+capitalise(key)
      const storeValue = JSON.stringify(content.tiers[key])
      this.$store.commit(storeKey,storeValue)
    }
    this.$store.commit("setLines",JSON.stringify(content.lines))
    content = JSON.parse(result)
    let i=0;
    let k=0;
    console.log(this)
    for(const page of content) {
      if (this.hasProperty(page,'fields')){
        for (const field of page.fields){
          if (field.prop !== null && field.prop.length > 0){
            for (let j=1; j< urlParams.length ;j++){
              var param = urlParams[j].split("=")
              if(param[0]===field.prop){
                content[k].fields[i].value  = decodeURI(param[1])
              }
            }
          }
          i++;
        }
      }
      k++;
    }
  },
};


function getDataByPageName(pageName){  
        //console.log("Getting via pageName::"+pageName[0])
        const api = (window.location.host.toLowerCase().includes("localhost"))?
              "https://astondive.co.uk/law/staging/" : "https://astondive.co.uk/law/v1/"
        //console.log("API::"+api)
        var url= api+"getContent.php?data="+pageName[0];

        var xhr = new XMLHttpRequest()
        xhr.open('GET',url, false)
        xhr.send(null)
        if (xhr.status === 200) {
          return xhr.response;
        } else {
          throw new Error('Request failed: ' + xhr.statusText);
        }
      }

function getContentByPageName(pageName){  
        //console.log("Getting via pageName::"+pageName[0])
        const api = (window.location.host.toLowerCase().includes("localhost"))?
              "https://astondive.co.uk/law/staging/" : "https://astondive.co.uk/law/v1/"
        //console.log("API::"+api)
        var url= api+"getContent.php?page="+pageName[0];

        var xhr = new XMLHttpRequest()
        xhr.open('GET',url, false)
        xhr.send(null)
        if (xhr.status === 200) {
          return xhr.response;
        } else {
          throw new Error('Request failed: ' + xhr.statusText);
        }
      }

</script>

<style>
#checkboxLabel {
   font-size: 10px;
}
.purple {
  background-color: blueviolet;
}
.white--text{
  color: white;
}
</style>