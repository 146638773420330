<template>
  <v-card v-if="!loggedIn" width="400" class="mx-auto mt-5">
    <v-card-title>
      <h1 class="display">Login</h1>
    </v-card-title>
    <v-card-text>
      <v-form>
        <v-text-field 
            label="Username"
            v-model="user.email"
            prepend-icon="mdi-account-circle">
        </v-text-field>
        <v-text-field 
          :type="showPassword?'text': 'password'" 
          label="Password"
          v-model="user.password"
          prepend-icon="mdi-lock"
          :append-icon="showPassword?'mdi-eye':'mdi-eye-off'"
          @click:append="showPassword = !showPassword">
        </v-text-field>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-btn color="success" disabled>Register</v-btn>
      <v-spacer></v-spacer>
      <v-btn v-on:click="userLogin" color="info" >Login</v-btn>
    </v-card-actions>
  </v-card>
  <v-card v-if="loggedIn" width="400" class="mx-auto mt-5">
    <v-card-title>
      <h3 class="display">Welcome {{user.email}}</h3>
    </v-card-title>
    <v-card-text>
      <v-form>
  
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-btn color="success" disabled>Register</v-btn>
      <v-spacer></v-spacer>
      <v-btn v-on:click="userLogout" color="info" >Log Out</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>

// import firebase from 'firebase/app';
// import 'firebase/auth';
import {getAuth, signOut, signInWithEmailAndPassword} from 'firebase/auth';
export default {
  name: 'LoginPage',
  data: () => {
  return {
    showPassword: false,
      user : {
        email: '', password: ''
      },
    loggedIn: false
    }
  },
  methods: {
    isLoggedIn(){
      // console.log("is Logged  in")
      const auth = getAuth();
      // console.log(auth)
      if (auth.currentUser != null){
        // console.log(auth.currentUser)    
        // console.log("false")
        this.loggedIn = true;
      } 
      
      // console.log("false")
      // console.log(auth.currentUser)
      this.loggedIn = false;
    },
    userLogout(){
      // console.log("Log out")
      const auth = getAuth();
      // console.log(auth)
      signOut(auth).then(() => {
        console.log ("Signed out")
          this.$store.commit("setName", null)
          this.$store.commit("setAccessToken", null)
          this.loggedIn = false;
      }).catch((error) => {
      console.log(`signed out - never mind ${error}`)
      });
    },
    userLogin() {
      // console.log("Log in")
      const auth = getAuth();
      // console.log(auth)
      signOut(auth).then(() => {
        // console.log ("Signed out")
        this.loggedIn = false;
      }).catch((error) => {
      console.log(`signed out - never mind ${error}`)
      });

      console.log(`Login pressed ${this.user.email} ${ this.user.password}`)
      signInWithEmailAndPassword(auth,this.user.email, this.user.password)
        .then(() => {
          // console.log (`name ${auth.currentUser.email}`)
          this.$store.commit("setName", auth.currentUser.email)
          this.$store.commit("setAccessToken", auth.currentUser.accessToken)
          var currentUser = auth.currentUser.toJSON()
          this.$store.commit("setLastLogin", currentUser.lastLoginAt)
          this.loggedIn = true;
        })
        .catch((error) => {
          alert(error.message);
        });
    }
  }
}
</script>