<template>
  <v-container>
    <v-spacer />
    <router-link to="/contact">
      <v-row no-gutters>
        <v-col  cols="12" xl="12" lg="12" md="12" sm="12" sx="12">
          <v-parallax
            height="300"
            src="../assets/wharf.jpg"
          >
          </v-parallax>
          <p style="text-align: justify">
          
          Quality and excellence are the essence of what Aston-Dive Legal Services represents. 
          We pride ourselves on offering a modern, flexible approach to law whilst maintaining 
          the same level of quality and professional excellence you would expect from an 
          established traditional firm.
         </p>
          <v-chip-group column center-active>
            <router-link to="/other"><v-chip>More</v-chip></router-link>
            <router-link to="/flexibility"><v-chip>Flexibility</v-chip></router-link>
            <router-link to="/fixedfee"><v-chip>Fixed fee</v-chip></router-link>
            <router-link to="/customerservice"><v-chip>Customer service</v-chip></router-link>
          </v-chip-group>
          <br/>
           <p style="text-align: justify">
          <v-btn  color="black" dark  to="Contact/Quality"> Contact Us </v-btn>
          </p>
        </v-col>
      </v-row>
    </router-link>
  </v-container>
</template>

<style scoped>
a:link {
  text-decoration: none;
}
</style>
