<template>
  <div>
    <v-app-bar app color="#0088ff" dark>
      <v-app-bar-nav-icon  @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <v-toolbar-title  >
        Aston-Dive Legal Services
      </v-toolbar-title>
      <v-spacer></v-spacer>
          <v-chip  flat :ripple="false" rounded class="pa-2 ma-1" color="white" router to="/" exact-active-class="pa-2 ma-1" >
            <v-avatar left><v-icon style="color: white">mdi-home</v-icon></v-avatar>
            <span  class=" d-none d-sm-block">Home</span>          
          </v-chip>
          <v-chip v-if="loggedIn" flat :ripple="false" rounded class="pa-2 ma-1" color="white" router to="/pdf"  exact-active-class="pa-2 ma-1" >
            <v-avatar left><v-icon style="color: white">mdi-file-pdf-box</v-icon></v-avatar>
            <span  class=" d-none d-sm-block">PDF</span>          
          </v-chip>  
          <v-chip v-if="loggedIn" flat :ripple="false" rounded class="pa-2 ma-1" color="white" router to="/board"  exact-active-class="pa-2 ma-1" >
            <v-avatar left><v-icon style="color: white">mdi-monitor-dashboard</v-icon></v-avatar>
            <span  class=" d-none d-sm-block">Board</span>          
          </v-chip>  
            <v-chip v-if="loggedIn" flat :ripple="false" rounded class="pa-2 ma-1" color="white" router to="/property" exact-active-class="pa-2 ma-1" >
            <v-avatar left><v-icon style="color: white">mdi-calculator</v-icon></v-avatar>
            <span  class=" d-none d-sm-block">Calc</span>          
          </v-chip>   
            <v-chip v-if="loggedIn" flat :ripple="false" rounded class="pa-2 ma-1" color="white" router to="/documentLinks"  exact-active-class="pa-2 ma-1" >
            <v-avatar left><v-icon style="color: white">mdi-email-send</v-icon></v-avatar>
            <span  class=" d-none d-sm-block">Forms</span>          
          </v-chip>   
          <v-chip v-if="loggedIn" flat :ripple="false" rounded class="pa-2 ma-1" color="white" @click="logout" exact-active-class="pa-2 ma-1" >
            <v-avatar left><v-icon style="color: white">mdi-logout</v-icon></v-avatar>
            <span  class=" d-none d-sm-block">Logout</span>          
          </v-chip>   
          <v-chip v-if="!loggedIn" flat :ripple="false" rounded class="pa-2 ma-1" color="white" router to="/login" exact-active-class="pa-2 ma-1" >
            <v-avatar left><v-icon style="color: white">mdi-login</v-icon></v-avatar>
            <span  class=" d-none d-sm-block">Login</span>          
          </v-chip>   

    
    </v-app-bar>
        
    <v-navigation-drawer  v-model="drawer" temporary> 
      <v-list  v-for="item in items" :key="item.title" >    
        <v-list-item :prepend-icon="item.icon" :title="item.title" router :to="item.route" link />
      </v-list> 
    </v-navigation-drawer>
    </div>
</template>

<script>
  
// import firebase from 'firebase/app';
// import 'firebase/auth';
import {getAuth, signOut } from 'firebase/auth';

import { mapGetters } from "vuex";
import store from '../store'

  export default {
    data () {
      return {
        drawer: null,
        items: [
          { title: 'Privacy Policy', icon: 'mdi-text-box', route: '/privacyPolicy' },
          { title: 'Terms of Use', icon: 'mdi-text-box', route: '/termsOfUse' },
          { title: 'Complaints Procedure', icon: 'mdi-text-box', route: '/complaintsProcedure' },
        ],
      }
    },
    computed: {
      ...mapGetters({
        userName: "getName",
      }),
      loggedIn: {
        get() {
          if (this.userName === null){
            return false;
          }
          if (this.userName === undefined){
            return false;
          }
          if (this.userName.length === 0){
            return false;
          }
          return true;
        } 
      }
    },
    methods: {
      logout() {
          signOut(getAuth()).then(function() {
          store.commit("setName", null)
        }). catch( function(error){  
          console.log(error)
          store.commit("setName", null)
        })

        this.$router.go(0)
      }
    }
  }
</script>