    UserModule
import { createStore } from 'vuex'
import { PropertyModule } from './modules/property'
import { UserModule } from './modules/user'

export default createStore({
  modules: {
    PropertyModule,
    UserModule
  }
})
