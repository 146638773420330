<template>
    <div style= "width: 100%" class="flex-grow-0 ma-0 pa-0">
        <v-row no-gutters>
            <v-col  cols="12" xl="12" lg="12" md="12" sm="12" sx="12">
                <v-dialog v-model="dialog" persistent max-width="600px" >
                    <template v-slot:activator="{ on }" >
                        <v-chip-group column center-active>
                            <v-chip class="ma-2" Add color="green" v-on="on" outlined @click="dialog = true">
                                <v-avatar left>
                                    <v-icon>mdi-plus</v-icon>
                                </v-avatar>
                                Add Client
                            </v-chip>
                        </v-chip-group>
                    </template>
                    <v-card>
                        <v-card-title>
                            <span class="headline">User Profile</span>
                        </v-card-title>
                        <v-card-text class="popup">
                            <div>
                                <div class="d-flex flex-column mb-6 bg-surface-variant">
                                        <v-text-field label="Company Name" hint="Only required if a company or a business"   persistent-hint v-model="add_client.company_name"> </v-text-field>
                                        <v-text-field label="Legal first name*" v-model="add_client.first_name" required> </v-text-field>
                                        <v-text-field label="Legal middle name" hint="Middle"></v-text-field>
                                        <v-text-field label="Legal last name*" v-model="add_client.surname" hint="Surname"  persistent-hint required></v-text-field>
                                        <v-text-field label="Email*" v-model="add_client.email" required></v-text-field>
                                        <v-text-field label="Phone Number" required  v-model="add_client.phone"></v-text-field>
                                        <v-text-field label="House Name/Number" v-model="add_client.house_number_name" /> 
                                        <v-text-field label="Street" v-model="add_client.street" /> 
                                        <v-text-field label="Village" v-model="add_client.village" /> 
                                        <v-text-field label="Town/City" v-model="add_client.town_city" /> 
                                        <v-text-field label="County" v-model="add_client.county" /> 
                                        <v-text-field label="Country" v-model="add_client.country" /> 
                                        <v-text-field label="Post Code" v-model="add_client.postcode" /> 
                                </div>
                            </div>
                            <small>*indicates required field</small>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="blue darken-1" flat @click="dialog = false">Close</v-btn>
                            <v-btn color="blue darken-1" flat @click="addClientCard()">Save</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-col>
        </v-row>
        <v-row no-gutters>
            <div class="column" v-for="(column, index) in columns" :key="index"> 
                <div class="column-header" draggable="true"
                        @dragover.prevent 
                        @drop="columnDrop($event,index)">
                    <v-card elevation="0" max-width="200" >
                        <v-card-title style="font-size: 12px"> {{ column.title }} </v-card-title>
                    </v-card>
                </div>
                <div class="card" v-for="(card, cardIndex) in column.cards" :key="cardIndex" draggable="true"
                        @dragstart="startDrag($event, card, index, cardIndex)" 
                        @dragover.prevent 
                        @drop="endDrag($event, card, index, cardIndex)">
                    <v-card elevation="2" max-width="200">
                        <v-card-title> {{ card.title }} </v-card-title>
                    <v-card-actions>
                        <v-btn color="orange lighten-2" text> Explore {{ columns[index].cards[cardIndex].getShow() }}  {{ index }}   {{ cardIndex }}                        </v-btn>
                        <v-spacer></v-spacer>
                        <v-btn icon @click="toggleCardDetail(index, cardIndex)">
                            <!-- <v-icon>{{ card.getShow() ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon> -->
                            <v-icon>{{ isCardDetailShown(index, cardIndex) ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                        </v-btn>
                    </v-card-actions>
                    <v-expand-transition>
                        <div v-if="columns[index].cards[cardIndex].getShow()  ">
                            <v-divider></v-divider>
                            <v-card-text>
                            {{ card.notes }}
                            </v-card-text>
                        </div>
                    </v-expand-transition>
                    </v-card>
                </div>
            </div>
        </v-row>
    </div>
</template>

<script>

//import { DialogModal,DialogAlert } from 'v-dialogs'

// import firebase from 'firebase/app';
// import 'firebase/auth';

import {getAuth } from 'firebase/auth';
import axios from 'axios'
import Card from '../model/Card'
import Client from '../model/Client'

export default {
    
 
    data() {
        return {
            token: null,
            dialog: false,
            documnents: [],
            forms: [{name:'Notarial Tems of business', form:'Notarial', type: 1}],
            form : {
                name: '',
                id:'',
                email:'',
                subject:'',
                message:'Please follow the link {url} to fill in the form',
                selected: {name:'Notarial Tems of business', form:'Notatrial', type:1},
            },
            clients: [],
            columns: [
                {
                    title: 'Prospect',
                    index: 0,
                    cards: []
                },{
                    title: 'Terms Sent',
                    index: 1,
                    cards: []
                },{
                    title: 'Client',
                    index: 2,
                    cards: []
                },{
                    title: 'Appointment',
                    index: 3,
                    cards: []
                },{
                    title: 'In Progress',
                    index: 4,
                    cards: []
                },{
                    title: 'Done',
                    index: 5,
                    cards: []
                }
            ],
            add_client : {
                email: '', user_id: null,  company_name: '', title: '',  first_name: '',  
                other_names: '',  surname: '',  house_number_name: '',  street: '',  phone: '',
                village: '',  town_city: '',  county: '',  country: 'UK',  postcode: ''
            },
            draggedCard: null,
            sourceColumnIndex: null,
            sourceCardIndex: null
        };
    },
    mounted() {
        getAuth().currentUser.getIdToken().then((token) => {
            this.token = token
            // console.log (JSON.stringify(token))
            const body = JSON.stringify({
                    "company":0,
                    "limit":10,
                    "page":0
                });
            axios.post('https://astondive.co.uk/law/v1/getCards.php', body,
            {
                headers: {  "Authorisation":"bearer "+token,
                            'Access-Control-Allow-Origin': '*',
                            'Content-Type': 'application/json'},
            }).then(function (response){
                // console.log("Auth current user")
                // console.log(response)
                var results = response.data.cards
                for (let i = 0; i < results.length; i++) {
                    var card = new Card(results[i])
                    // console.log("RESULTS ["+results.length+"]::"+JSON.stringify(card))
                    this.addCardToBoardAndRefreshClient(card)
                }
            }.bind(this));
        })
    },
    methods: {
        isCardDetailShown(col, index){
            // var col = column.index
            var maxCol = this.columns.length-1;
            if (col > maxCol ){
                console.error("Index out of range columns["+col+"] ("+index+")")
                return false
            }
            if (index >= this.columns[col].cards.length){
                console.error("Index out of range columns["+col+"] ("+index+")")
                return false
            }
            var currentCol = this.columns[col].cards.map(x => ({ id:x.id, column: x.column, index:x.arrangement}))
            console.log(" columns["+col+"] = "+JSON.stringify(currentCol)+")")
            return this.columns[col].cards[index].getShow()
        },
        toggleCardDetail(col, index){
            // var col = column.index
            var card =  this.columns[col].cards[index];
            var old = card.getShow()
            card.setShow(!old)
            // console.log("Toggle ("+col+","+index+") + card = "+JSON.stringify(card))
            return card.getShow()
        },
        addCardToBoardAndRefreshClient(card){
            var col = card.getColumn()
            // console.log("addCardToBoardAndRefreshClient"+ col)
            this.columns[col].cards.push(card);
            this.columns[col].cards.sort(
                                function(cardA,cardB){
                                    return cardA.getArrangement()-cardB.getArrangement()
                                })
            return true
        },
        addClientCard() {
            this.dialog = false;
            var total = 1;
            this.columns.forEach((column/*,i*/) => {
                // console.info(JSON.stringify(column.cards))
                // console.info(i)
                column.cards.forEach((/*card,j*/) => {
                    // console.log(`card no ${j}`)
                    // console.log(card)
                    total = total + 1} )
            })
            const client = new Client(this.add_client)
            // console.log ("Body::"+client.getRequestCreateBody())
            // console.log ("Authorisation : bearer "+this.token)
            axios   .post('https://astondive.co.uk/law/v1/createClient.php', 
                                client.getRequestCreateBody(),
                                { headers: {"Authorisation":"bearer "+this.token,
                                            'Access-Control-Allow-Origin': '*',
                                            'Content-Type': 'application/json'}})
                    .then( (response ) => this.addClientAndCreateCard (response))
                
        },
        addClientAndCreateCard(response){
            if (response.status === 201 || response.status === 200){
                console.log("addClientAndCreateCard")
                console.log("======================")
                console.log(response.data)
                const client = new Client(JSON.parse(response.data))
                console.log(client)
                var size = this.clients.length
                this.clients[size] = client
                this.clients.sort(function(a,b){return (a.getName() < b.getName())})
            
                console.log (this.columns[0])
                var len = this.columns[0].cards.length
                var cid = client.getId()
                var name = client.getName()
                var card = new Card({ "id": null, "arrangement" : len, "title": name,"clientId"  : cid,"column" : 0 });
                this.moveCard(card,0,-1,false)
                var body = card.getRequestCreateBody()
                console.log ("body = ["+body+"]")
                console.log ("headers [ headers: {Authorisation:bearer "+this.token+"}]")
                axios.post('https://astondive.co.uk/law/v1/createCard.php', body,
                {
                    headers: {"Authorisation":"bearer "+this.token,
                            'Access-Control-Allow-Origin': '*',
                            'Content-Type': 'application/json'},
                }).then(function (response){
                    console.log("Created card  response = ");
                    console.log(response)
                });
            }
        },
        replaceCard(card){
            this.columns[card.getColumn()].cards.splice(card.getArrangement(), 1);
            this.columns[card.getColumn()].cards.splice(card.getArrangement(), 0, card);
        },
        moveCard(card,targetColumn,targetIdx,deleteOld){
            var originalCol = card.getColumn()
            var originalIdx = card.getArrangement()
            var bottom = this.columns[targetColumn].cards.length
            if (targetIdx === -1){
                targetIdx = bottom
            }
            // console.log("Move from ("+originalCol+","+originalIdx+") ")
            // console.log ("to ("+targetColumn+","+targetIdx+")")
            // console.log( deleteOld ? " moving" : " new"  )
            if (deleteOld){
                this.columns[originalCol].cards.splice(originalIdx, 1);
            }
            card.setArrangement(targetIdx)
            card.setColumn(targetColumn)
            this.columns[targetColumn].cards.splice(targetIdx,0, card);
            this.reorderColumn(originalCol)
            this.reorderColumn(targetColumn)
            this.saveBoard()
        },
        columnDrop(event, targetColumnIndex){
            this.moveCard(this.draggedCard,targetColumnIndex,-1,true)
        },
        startDrag(event,card, columnIdx, cardIdx) {
            this.draggedCard = card;
            this.sourceColumnIndex = columnIdx;
            this.sourceCardIndex = cardIdx;
        },
        reorderColumn(col){
            var size = this.columns[col].cards.length
            for (let i = 0; i< size; i++){
                this.columns[col].cards[i].setArrangement(i)
                this.columns[col].cards[i].setColumn(col)
            }
        },
        endDrag(event,targetCard, targetColumnIndex, targetCardIndex) {
            // console.log("Drag End")
            if (this.draggedCard === targetCard) {
                return;
            }
            this.columns[this.sourceColumnIndex].cards.splice(this.sourceCardIndex, 1);
            this.columns[targetColumnIndex].cards.splice(targetCardIndex, 0, this.draggedCard);
            this.reorderColumn(this.sourceColumnIndex)
            this.reorderColumn(targetColumnIndex)
            this.draggedCard = null;
            this.sourceColumnIndex = null;
            this.sourceCardIndex = null;
            this.saveBoard()
        },     
        saveBoard(){
            // console.log("Saving Board")
            var offset = 1;
            for (let i = 0; i< this.columns.length; i++){
                for (let j = 0; j < this.columns[i].cards.length; j++){ 
                    try{
                        var card = this.columns[i].cards[j]
                        var body = card.getRequestUpdateBody(offset)
                        // console.log ("body = ["+body+"]")
                        // console.log ("headers [ headers: {Authorisation:bearer "+this.token+"}]")
                        axios.post('https://astondive.co.uk/law/v1/updateCard.php', body,
                        {
                            headers: {"Authorisation":"bearer "+this.token,
                                'Access-Control-Allow-Origin': '*',
                                'Content-Type': 'application/json'},
                        // }).then(function (response){
                        //     console.log("Created card  response = ");
                        //     console.log(response)
                        });
                    } catch (Ex) {
                        console.log (Ex)
                    }
                    offset = 0
                }
            }
        },
        parseJwt (token) {
            var base64Url = token.split('.')[1];
            var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
            var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
            }).join(''));
            
            return JSON.parse(jsonPayload);     
        }
        // createClient(){
        //     firebase.auth().currentUser.getIdToken().then((token) => {
        //         const body = JSON.stringify(this.add_client);
        //         axios.post('https://astondive.co.uk/law/v1/createClient.php', body,
        //         {
        //             headers: {"Authorisation":"bearer "+token},
        //         }).then(function (response){

        //             if (response.status === 201 || response.status === 200){
        //                 this.client = response.data
        //                 this.state = 'status'
        //             }
        //         }).catch(err => {
        //             console.log('error', err.response.data)
        //             if (err.response.status === 409) {
        //                 console.log ("409")
        //                 if(confirm("Client already exists - overwrite data?")){
        //                     console.log (err.response.data.client)
        //                     const company_name = err.response.data.client.company_name;
        //                     const title = err.response.data.client.title
        //                     const first_name = err.response.data.client.first_name 
        //                     const other_names = err.response.data.client.other_names
        //                     const surname =  err.response.data.client.surname
        //                     const house_number_name = err.response.data.client.house_number_name
        //                     const street = err.response.data.client.street 
        //                     const village = err.response.data.client.village
        //                     const town_city = err.response.data.client.town_city
        //                     const county = err.response.data.client.county
        //                     const country = err.response.data.client.country
        //                     const postcode = err.response.data.client.postcode
        //                     this.client.id = err.response.data.id
        //                     this.client.user_id = err.response.data.user_id
        //                     this.client.email = this.add_client.email !== '' ? this.add_client.email : err.response.data.client.email
        //                     this.client.company_name = this.add_client.company_name !== '' ? this.add_client.company_name :company_name
        //                     this.client.title = this.add_client.title !== '' ? this.add_client.title : title
        //                     this.client.first_name = this.add_client.first_name !== '' ? this.add_client.first_name : first_name 
        //                     this.client.other_names = this.add_client.other_names !== '' ? this.add_client.other_names : other_names
        //                     this.client.surname = this.add_client.surname !== '' ? this.add_client.surname : surname
        //                     this.client.house_number_name = this.add_client.house_number_name !== '' ? this.add_client.house_number_name : house_number_name
        //                     this.client.street = this.add_client.street !== '' ? this.add_client.street : street 
        //                     this.client.village = this.add_client.village !== '' ? this.add_client.village : village
        //                     this.client.town_city = this.add_client.town_city !== '' ? this.add_client.town_city : town_city
        //                     this.client.county = this.add_client.county !== '' ? this.add_client.county : county
        //                     this.client.country = this.add_client.country !== '' ? this.add_client.country : country
        //                     this.client.postcode = this.add_client.postcode !== '' ? this.add_client.postcode : postcode
        //                     this.state = 'edit'
        //                 }
        //             }
        //         })               
        //     })
        // },

    }
};
</script>

<style>

.container{
    overflow-x: scroll;
    padding:0px;
 }

.kanban-board {
    display: flex;
    column-gap: 1px;
    row-gap: 4px; 
}

.popup {
    overflow-y: scroll;
}

.column {
    display: d-flex;
    flex: 1;
    flex-wrap: nowrap;
    max-width:200px;
    background-color: rgb(0, 255, 13);
    padding: 0px;
    gap: 1px;
}

.column-header {
    flex-shrink: 3;
    gap: 0px;
    padding: 0px;
    max-width: 200px;
    background-color: rgb(145, 91, 19);
    margin-bottom: 10px; 
    height: 50px;
    text-align: center;
    vertical-align: center;
    overflow-y: hidden;
    font-size: 10px
}

.card {
    flex-shrink: 3;
    background-color: rgb(33, 11, 98);
    padding: 0px;
    margin-bottom: 10px;
    max-width: 200px;
    width: "200px";
    cursor: grab;
}
</style>