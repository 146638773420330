

export default class Card {
    constructor(copy){
        console.log("copy:"+JSON.stringify(copy))
        for (var prop in copy){
            console.log(prop + " : " + copy[prop]);
            if(prop === 'id') {
                this.id = copy[prop]
            } else if(prop === 'arrangement') {
                this.arrangement = copy[prop]
            } else if(prop === 'tenant') {
                this.tenant      = copy[prop]
            } else if(prop === 'assigned') {
                this.assigned    = copy[prop]
            } else if (prop === 'title') {
                this.title       = copy[prop]
            } else if(prop === 'clientId') {
                this.clientId    = copy[prop]
            } else if(prop === 'documentId') {
                this.documentId  = copy[prop]
            } else if(prop === 'column') {
                this.column      = copy[prop]
            } else if(prop === 'show') {
                this.show        = copy[prop]
            } else if(prop === 'notes') {
                this.notes       = copy[prop]
            } 
        }
        console.log(JSON.stringify(this))
    }

    getTitle() {
        return this.title;
    }

    getShow(){
        return this.show
    }

    setShow(value){
        this.show  = value;
    }

    getArrangement(){
        return this.arrangement
    }
    setArrangement(value){
        this.arrangement=value
    }

    getColumn(){
        return this.column
    }
    setColumn(value){
        this.column = value;
    }

    getRequestCreateBody(){
        var body = new Object();

        body.arrangement = this.arrangement
        body.title = this.title
        body.clientId = this.clientId
        body.documentId = this.documentId
        body.column = this.column
        
        return JSON.stringify(body)
    }


    getRequestUpdateBody(offset){
        var body = new Object();
        body.id = this.id
        body.arrangement = this.arrangement
        body.title = this.title
        body.tenant = this.tenant
        body.clientId = this.clientId
        body.documentId = this.documentId
        body.column = this.column
        body.offset = offset
        body.board=0

        
        return JSON.stringify(body)
    }
}