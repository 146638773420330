

export default class Client {
    constructor(copy){
        this.id="";
        this.email="";
        this.user_id="";
        this.tenant="";
        this.company_name="";
        this.title="";
        this.first_name="";
        this.other_names="";
        this.surname="";
        this.house_number_name="";
        this.street="";
        this.village="";
        this.town_city="";
        this.county="";
        this.country="";
        this.postcode="";
        console.log("copy:"+JSON.stringify(copy))
        for (var prop in copy){
            console.log (`prop ${prop}`)
            console.log (`prop ${copy[prop]}`)
            this[prop] = copy[prop]
            console.log(this[prop])
        }
        console.log(JSON.stringify(this))
    }
    getTitle() {
        return this.title;
    }

    setTitle(value){
        this.title  = value;
    }

    getId(){
        return this.id;
    }

    getName(){
        const initial = (this.other_names===null||this.other_names===""||this.other_names.length===0)?" ":" "+this.other_names.substring(0,1)+" "
        const name = this.first_name+ initial + this.surname
        console.log (`name ${name}`)
        return name
    }

    getCompany(){
        return this.company
    }
    setCompany(value){
        this.company=value
    }

    getColumn(){
        return this.column
    }
    setColumn(value){
        this.column = value;
    }

    getRequestCreateBody(){
        var body = new Object();

        body.id = this.id;
        body.email = this.email;
        body.user_id = this.user_id;
        body.tenant = this.tenant;
        body.company_name = this.company_name;
        body.title = this.title;
        body.first_name = this.first_name;
        body.other_names = this.other_names;
        body.surname = this.surname;
        body.house_number_name = this.house_number_name;
        body.street = this.street;
        body.village = this.village;
        body.town_city = this.town_city;
        body.county = this.county;
        body.country = this.country;
        body.postcode = this.postcode;
        
        return JSON.stringify(body)
    }
}