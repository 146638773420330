// import axios from "axios";

export const UserModule = {
    state: () => ( {
    name: null,
    accessToken: null,
    lastLogin :null
  }),
  getters: {
    getName: state => state.name,
    getAccessToken: state => state.accessToken,
    getLastLogin: state => state.lastLogin,
  },

  mutations: {
    setName: (state, value) => {
      state.name = value;
    },  setAccessToken: (state, value) => {
      state.accessToken = value;
    }, 
    setLastLogin: (state, value) => {
      state.accessToken = value;
    },
  }
}